import React from "react";
import TabSlider from "../components/tabsComponent";
import SearchBar from "../components/SearchBar/SearchBar";
import PriceTable from "../components/newTable/priceTable";
import {
  pricing,
  setConfig,
  setFilteredRedux,
  setPageCount,
  setPageSizeRedux,
  setPageRedux,
  setSorting,
  setUserTable,
  setActiveTab,
  setIsPreFiltered,
  setPreDefinedFilter,
} from "../redux/slices/pricingSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetConfigQuery,
  useGetFilteredDataMutation,
  useGetSupplierTableWithMultiSortingQuery,
  useGetSupplierTableWithSortingQuery,
  useGetSupplierTableWithStateQuery,
} from "../redux/slices/apiSlice";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import DownloadMenu from "../components/downloadMenu";
import { useLocation, useNavigate} from "react-router-dom";
import {
  cn,
  convertToJsonObject,
  filterKeys,
  mapping,
  sortMapping,
  filterReverseMappingSavedSearch
} from "../utils/utils";
import { useEffect } from "react";
import { auth } from "../redux/slices/authSlice";
import amplitude from "../utils/ampli";
import TopFilterDrawer from "../components/ui/topFilterDrawer";
import { Lock, Check, ListTodo, ListChecks, FilterIcon } from 'lucide-react';
import BulkLock from "../components/bulkLock";

function useQuery() {
  const { search } = useLocation();

  return new URLSearchParams(search);
}

const PriceCoPilot = ({ accountDetails }) => {
  let query = useQuery(); // convert the params to search items.
  const [page, setPage] = useState(parseInt(query.get("page")) ?? pageRedux);
  const pageRedux = useSelector(pricing).page;

  if (query.get("page") == null) {
    query.set("page", pageRedux);
  }

  const token = useSelector(auth).token;
  const pSize = useSelector(pricing).pageSize;
  const isPreFilteredRedux = useSelector(pricing).isPreFiltered;
  const preDefinedFilterRedux = useSelector(pricing).preDefinedFilter;
  const preDefinedSortRedux = useSelector(pricing).preDefinedSort;

//   if (isPreFilteredRedux && preDefinedFilterRedux && preDefinedSortRedux){
//   console.log("isPreFilteredRedux", isPreFilteredRedux, "preDefinedFilterRedux", preDefinedFilterRedux)
// }
  const qPage = query.get("page");

  useEffect(() => {
    if (qPage !== pageRedux) {
      dispatch(setPageRedux(qPage));
    }
    setPage(parseInt(qPage));
  }, [qPage]);

  // get the page number
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const userTable = useSelector(pricing).userTable;

  const [pageSize, setPageSize] = React.useState(pSize);

  const sort_by = useSelector(pricing)?.config?.sort_by;

  const sortByMapping = {
    sell_offer_cents: "sell_offer_cents",
    rap_discount: "rap_percent",
    price_per_carat: "price_per_carat",
  };

  useEffect(() => {
    if (sort_by && sortState.length == 0) {
      const x = {
        sorting: true,
        asc_desc: "asc",
        sort_by: sortByMapping[sort_by],
      };
      setSortState([x]);
    }
  }, [sort_by]);

  // const [sorting, setSorting] = React.useState({
  //   sorting: false,
  //   asc_desc: "asc",
  //   sort_by: "sell_offer_cents",
  // });

  const searchRedux = useSelector(pricing).searchParam;
  const filteredRedux = useSelector(pricing).filtered;
  const [prevSearch, setPrevSearch] = useState("");

  const [searchParam, setSearchParam] = useState(searchRedux);
  const activeTabRedux = useSelector(pricing).activeTab;
  const [activeTab, setActiveTab] = useState(activeTabRedux);
  const [filtered, setFiltered] = useState(filteredRedux);
  const [isPreFiltered, setIsPreFilteredLocal] = useState(isPreFilteredRedux);
  const [preDefinedFilter, setPreDefinedFilter] = useState(preDefinedFilterRedux);
  const [preDefinedSort, setPreDefinedSort] = useState(preDefinedSortRedux);

  // const inputObject = {
  //   "0": {
  //     "attr_types.diamond#color": "asc"
  //   },
  //   "1": {
  //     "item_carat_range": "desc"
  //   },
  //   "2": {
  //     "attr_types.diamond#color": "asc"
  //   },
  //   "3": {
  //     "attr_types.diamond#clarity": "desc"
  //   }
  // };

function convertSortObjectToArray(value) {
  return Object.keys(value).map(key => {
    const innerObject = value[key];
    const sortBy = Object.keys(innerObject)[0];
    const ascDesc = innerObject[sortBy];
    
    return {
      sorting: true,
      asc_desc: ascDesc,
      sort_by: sortBy
    };
  });
}
  
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/price_co-pilot");
    }
  }, [navigate, location]);

  const setPageSizeFn = (ev) => {
    setPage(0);
    navigate(`/price_co-pilot?page=0`);
    setPageSize(parseInt(ev.target.value, 10));
    dispatch(setPageSizeRedux(parseInt(ev.target.value, 10)));
  };

  let sorting = useSelector(pricing).sorting;

  // BUG - While first time loading the page - sorting is undefined, so backend request failed to give response
  // Applying default multisort in the color(D, E, F) and clarity (VVS1, VVS2, VS1, VS2 ....)
  if (sorting.length === 0) {
    sorting = [
      {
        sorting: true,
        asc_desc: "asc",
        sort_by: "attr_types.diamond#shape"
      },
      {
        sorting: true,
        asc_desc: "desc",
        sort_by: "item_carat_range"
      },
      {
        sorting: true,
        asc_desc: "asc",
        sort_by: "attr_types.diamond#color"
      },
      {
        sorting: true,
        asc_desc: "asc",
        sort_by: "attr_types.diamond#clarity"
      },
      
      
    ];
  }
  
  // if(preDefinedSort){
  //   console.log("preDefinedSort", savedSearchSort)
  //   sorting = savedSearchSort;
  // }

  const defaultMultisort = [
    {
      sorting: true,
      asc_desc: "asc",
      sort_by: "attr_types.diamond#color"
    },
    {
      sorting: true,
      asc_desc: "asc",
      sort_by: "attr_types.diamond#clarity"
    }
  ];

  const [sortState, setSortState] = useState(sorting);
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };

  const [tabState, setTabState] = useState({
    data: null,
    isLoading: null,
    isSuccess: null,
    isError: null,
    error: null,
    isFetching: null,
    currentData: null,
    total_matching_count: null,
  });

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    isFetching,
    currentData,
  } = useGetSupplierTableWithMultiSortingQuery(
    {
      id: accountDetails.account_id,
      from: page ? pageSize * page : 0,
      size: pageSize,
      asc_desc: sortState[0]?.asc_desc ?? "asc",
      sort_by: sortState[0]?.sort_by ?? null,
      search: searchParam,
      sort: sortState,
      // Change the below index, if the ordering changes in tabsList
      // tabsList={[
      //  {key: "All", value: "all", width: "100px", index: 0},
      //  {key: "Pending", value: "pending", width: "100px", index: 1},
      //  {key: "Locked", value: "locked", width: "100px", index: 2},
      //  {key: "Repriced", value: "repriced", width: "100px", index: 3},
      // ]}
      state: activeTab == 3 ? "repriced" : "",
      is_filter_locked: activeTab == 1 ? false : activeTab == 2 ? true : "",
      isSearched: searchParam !== prevSearch,
    },
    {
      skip: filtered.length > 0 || isPreFiltered,
    }
  );

  useEffect(() => {
    query.set("page", 0);
    navigate("/price_co-pilot"); // reset the pagination by reseting the url.
    // if (activeTab > 0) setSkipRepriced(false);
  }, [activeTab]);

  useEffect(() => {
    if (isFetching && !currentData) {
      // console.log("is fetching is called");
      dispatch(setUserTable(null));
      dispatch(setPageCount(0));
    }
  }, [currentData, isFetching]);

  useEffect(() => {
    if (data && filtered.length == 0) {
      dispatch(setUserTable(data?.data.map((item) => mapping(item))));
      dispatch(setPageCount(data.total_matching_count));
      setTabState({
        data: data?.data,
        isLoading,
        isSuccess,
        isError,
        error,
        isFetching,
        currentData,
        total_matching_count: data?.total_matching_count,
      });
    }
  }, [data]);

  // config.

  // const {
  //   data: configData,
  //   isLoading: configLoading,
  //   isSuccess: configSuccess,
  //   isError: configIsError,
  //   error: configError,
  // } = useGetConfigQuery({
  //   accId: accountDetails.account_id,
  // });

  // useEffect(() => {
  //   if (configData) {
  //     dispatch(setConfig(configData?.data));
  //   }
  // }, [configData]);

  function transformFilters(transformedFilters) {
    const newFormat = [];
    
    // Item filter from and to
    if (transformedFilters.item_filter.discrete) {
      for (const [key, values] of Object.entries(transformedFilters.item_filter.discrete)) {
        const filterKey = filterReverseMappingSavedSearch[key]; // e.g., "diamond#symmetry" -> "Symmetry"
        const values_new = values.map(value => value.toString().slice(4).toLowerCase()); // e.g ["0000ex"] to ["ex"]

        let filterKeynew = filterKey.toString().toLowerCase()
        if (filterKey == "Symmetry"){
          filterKeynew = "sym"
        } else if (filterKey == "Fluor"){
          filterKeynew = "flr" 
        } else if (filterKey == "Lab"){
          filterKeynew = "lab"
        } else if (filterKey == "Location"){
          filterKeynew = "locations"
        }

        if (filterKeys[filterKeynew]) {
          const remainingValues = filterKeys[filterKeynew].filter(value => !values_new.includes(value.toString().toLowerCase())); // ["VG", "Good", "Fair", "Poor"]
          newFormat.push({ id: filterKey, value: remainingValues }); //{id: Symmetry, value: ["VG", "Good", "Fair", "Poor"]}
        }
      }
    }

    // item filter text
    if (transformedFilters.item_filter.text) {
      // {"diamond#key_to_symbol": "bruise,cavity,chip,cloud,crystal,etch_channel,extra_facet,feather,grain_center,indented_natural,internal_graining,knot,laser_drilling,manufacturing_remnant,minor_details_of_polish,natural,needle,pinpoint,reflecting,surface_graining,twinning_wisp"}
      for (const [key, values] of Object.entries(transformedFilters.item_filter.text)) {
        const filterKey = filterReverseMappingSavedSearch[key]; // filterKey = "Key To Symbol"
        newFormat.push({ id: filterKey, value: values.split(",") }); //{ id: "Key To Symbol", value: "bruise", "cavity", "chip", "cloud", "crystal", "etch_channel", "extra_facet", "feather", "grain_center", "indented_natural", "internal_graining", "knot", "laser_drilling", "manufacturing_remnant", "minor_details_of_polish", "natural", "needle", "pinpoint", "reflecting", "surface_graining", "twinning_wisp"}
      }
    }
    
    // item filter from and to
    if (transformedFilters.item_filter.from || transformedFilters.item_filter.to) {
      for (const key of Object.keys(transformedFilters.item_filter.from || {})) {
        const filterKey = filterReverseMappingSavedSearch[key];
        const minValue = transformedFilters.item_filter.from[key];
        const maxValue = transformedFilters.item_filter.to?.[key];
        newFormat.push({ id: filterKey, value: { min: minValue, max: maxValue } });
      }
    }

    // Process from and to market filters
    if (transformedFilters.market_filters) {
      for (const key of Object.keys(transformedFilters.market_filters || {})) {
        const minValue = transformedFilters.market_filters[key].from;
        const maxValue = transformedFilters.market_filters[key].to;
        newFormat.push({ id: key, value: { min: minValue, max: maxValue } });
      }
    }

    console.log("transformedFilters", transformedFilters, "newFormat", newFormat)

  
    return newFormat;
  }
  
  // Helper function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [filteredData, { isLoading: loadingFiltered }] =
    useGetFilteredDataMutation();

  const fetchData = async () => {
    if ((filtered.length > 0 || isPreFiltered)) {
      // const page = 0
      const data = await filteredData({
        args: {
          id: accountDetails.account_id,
          from: 0,
          size: pageSize,
          asc_desc: sortState[0]?.asc_desc ?? "asc",
          sort_by: sortState[0]?.sort_by ?? "sell_offer_cents",
          search: searchParam,
          sort: sortState,
          state: activeTab == 3 ? "repriced" : "",
          is_filter_locked: activeTab == 1 ? false : activeTab == 2 ? true : "",
          isSearched: searchParam !== prevSearch,
        },
        body: isPreFiltered ? preDefinedFilter : convertToJsonObject(filtered),
      }).unwrap();
      dispatch(setUserTable(data?.data.map((item) => mapping(item))));
      dispatch(setPageCount(data?.total_matching_count));
      dispatch(setIsPreFiltered(false));
      setIsPreFilteredLocal(false);

    } else {
      dispatch(setUserTable([]));
      dispatch(setPageCount(0));
    }
    // dispatch(setPreDefinedFilter(0));
  };
  if (isPreFiltered){
    // fetchData(); 

    if(preDefinedFilter){
      const newFormat = transformFilters(preDefinedFilter);
      setFiltered(newFormat)
    }
    if(preDefinedSort){
      const newFormat = convertSortObjectToArray(preDefinedSort);
      setSortState(newFormat)
    }

    dispatch(setIsPreFiltered(false));
    setIsPreFilteredLocal(false);
    
  }

  useEffect(() => {
    (async () => {
      if (filtered.length > 0 && !isNaN(page)) {
        const data = await filteredData({
          args: {
            id: accountDetails.account_id,
            from: page ? pageSize * page : 0,
            size: pageSize,
            asc_desc: sortState[0]?.asc_desc ?? "asc",
            sort_by: sortState[0]?.sort_by ?? "sell_offer_cents",
            search: searchParam,
            sort: sortState,
            state: activeTab == 3 ? "repriced" : "",
            is_filter_locked: activeTab == 1 ? false : activeTab == 2 ? true : "",
            isSearched: searchParam !== prevSearch,
          },
          body: isPreFiltered ? preDefinedFilter : convertToJsonObject(filtered),

          // filtered = [{"id"=>"shape", "value" => ["princess", ..................]}, {}, {}]
          // convertToJsonObject(filtered) = {"item_filter"=>{"from"=>{}, "to"=>{}, "discrete"=>{"diamond#shape"=>["0000round"]}, "text"=>{}}}
        }).unwrap();
        // console.log({ data });

        dispatch(setUserTable(data?.data.map((item) => mapping(item))));
        dispatch(setPageCount(data?.total_matching_count));
      } else {
        dispatch(setUserTable(data?.data.map((item) => mapping(item))));
        dispatch(setPageCount(data?.total_matching_count));
      }
    })();
  }, [filtered, page, pageSize, sortState, searchParam, activeTab]);

  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectionChange = (selectedRows) => {
    setSelectedItems(selectedRows);
  };

  const handleClick = (event, header, type) => {
    // Check if shift key is pressed
    if (event.shiftKey) {
      // check if the current sort and type is present in array
      // if present remove the sort, if present and type is different then change the type

      setSortState((prev) => {
        let x = [...prev];

        let obj = x.find((item) => item.sort_by === sortMapping[header.id]);

        if (!obj || x.length === 0) {
          let data = {
            sorting: true,
            asc_desc: type,
            sort_by: sortMapping[header.id],
          };

          x.push(data);
        } else {
          if (obj.asc_desc === type) {
            x = x.filter((item) => item.sort_by !== sortMapping[header.id]);
          } else {
            x = x.map((item) => {
              if (item.sort_by === sortMapping[header.id]) {
                // Create a new object with the desired changes
                return {
                  ...item,
                  asc_desc: type,
                };
              }
              return item;
            });
          }
        }

        const buttonClickedEvent = {
          event_type: `v2_pricetable_MultiSort`,
          value: sortState,
        };
        amplitude.track(buttonClickedEvent);

        dispatch(setSorting(x));
        return x;
      });
    } else {
      // Handle regular click
      // console.log("Regular click");
      let data = {
        sorting: true,
        asc_desc: type,
        sort_by: sortMapping[header.id],
      };

      const len = sortState.length;
      if (len > 1) {
        const buttonClickedEvent = {
          event_type: `v2_pricetable_sort`,
          value: [data],
        };
        amplitude.track(buttonClickedEvent);
        setSortState([data]);
        dispatch(setSorting([data]));
      } else {
        if (
          sortState[0] &&
          sortState[0].sort_by == sortMapping[header.id] &&
          sortState[0].asc_desc == type
        ) {
          const buttonClickedEvent = {
            event_type: `v2_pricetable_sort`,
            value: [],
          };
          amplitude.track(buttonClickedEvent);

          setSortState([]);
          dispatch(setSorting([]));
        } else {
          const buttonClickedEvent = {
            event_type: `v2_pricetable_sort`,
            value: [data],
          };
          amplitude.track(buttonClickedEvent);

          setSortState([data]);
          dispatch(setSorting([data]));     
        }
      }
    }
    navigate(`/price_co-pilot?page=0`);
  };
 
  



  useEffect(() => {
    if (prevSearch !== searchParam) {
      setPrevSearch(searchParam);
    }
  }, [userTable]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  } else if (userTable) {
    return userTable ? (
      <div className="w-full h-[calc(100vh-50px)] flex flex-col ">
        <TopFilterDrawer
          open={openDrawer}
          onClose={(ev) => toggleDrawer(ev)}
          filtered={filtered}
          setFiltered={(value) => {
            navigate(`/price_co-pilot?page=0`);

            setFiltered(value);
          }}
        />
        <header className="min-h-[50px] w-full flex items-center overflow-x-scroll no-scrollbar justify-between pr-4 bg-white border-l-0 border-t-0 border-b border-solid border-[#EBEBEB] h-[50px] z-[100]">
          <div className="pl-4 flex xl:gap-4 lg:gap-1 items-center w-auto">
            <TabSlider
              tabsList={[
                {key: "All", value: "all", width: "70px", ind: 0, left: 0, icon: <ListChecks width='12'/>},
                {key: "Pending", value: "pending", width: "100px", ind: 1, left: 18, icon: <ListTodo width='12'/>},
                {key: "Locked", value: "locked", width: "100px", ind: 2, left: 45, icon: <Lock width='12'/>},
                {key: "Repriced", value: "repriced", width: "100px", ind: 3, left: 75, icon: <Check width='12'/>},
              ]} // If any changes are made in tabsList, we need to change the index where we using it (look for tabsList in this file).
              activeTab={activeTab}
              setActiveTabCurrent={setActiveTab}
            />
            <SearchBar
              setSearchParam={(value) => {
                setSearchParam(value);
                setPage(0);
                navigate(`/price_co-pilot?page=0`);
              }}
              searchParam={searchParam}
            />
          </div>
          {/* <button
            className="border-none rounded-lg  bg-[#A4D9FF]  max-w-[100px] max-h-[30px] px-4 py-1 flex justify-center items-center "
            onClick={fetchFile}
          >
            Download
          </button> */}
          <div className="flex justify-center w-auto items-center gap-2">
          <BulkLock selectedItems={selectedItems} filters={filtered} searchParam={searchParam} activeTab={activeTab}  />
            <DownloadMenu accountDetails={accountDetails} filters={filtered} searchParam={searchParam} activeTab={activeTab} sort={sortState} from={page ? pageSize * page : 0} size={pageSize} />
            <button
              className={cn(
                " self-end rounded-md flex gap-[0.200rem] items-center justify-center px-3 h-8 border-solid border-[1px] border-[#1E90FF] text-[#1E90FF] font-medium text-[0.75rem] w-max",
                filtered.length > 0
                
                  ? "bg-[#A4D9FF] text-[#1E90FF]"
                  : "bg-transparent text-[#1E90FF]"
              )}
              onClick={(ev) => {
                ev.stopPropagation();
                toggleDrawer(ev);
              }}
            >
            <FilterIcon className=' h-4 w-4' />  Search My Stock
            </button>
          </div>
        </header>
        <div className="h-full w-full overflow-auto">
          <PriceTable
            data={userTable}
            isRepricing={activeTab === 3}
            currentPage={page}
            setCurrentPage={(ev, page) => {
              navigate(`/price_co-pilot?page=${page}`);
            }}
            // handleOnSort={handleOnSort}
            handleOnSort2={handleClick}
            // sorting={sorting}
            isFetching={isFetching}
            currentData={currentData}
            setPageSize={setPageSizeFn}
            pageSize={pageSize}
            sortState={sortState}
            filterLoading={loadingFiltered}
            // sear={setSearchParam}
            onSelectionChange={handleSelectionChange}
          />
        </div>
      </div>
    ) : (
      <div>No items Available.</div>
    );
  } else if (isError) {
    return <div>{error}</div>;
  }
};

export default PriceCoPilot;
