import React, { useEffect, useRef, useState } from "react";

import StaticReport from "../StaticReport/StaticReport";
import TurnReport from "../TurnReport/TurnReport";
import { ColorRing } from "react-loader-spinner";
import Cookies from "js-cookie";
import Moment from "moment";
import AgingReport from "../AgingReport/AgingReport";
import "react-datepicker/dist/react-datepicker.css";
import Filter from "../FilterComponent/Filter";
import SummaryCard from "../SummaryCard/SummaryCard";
import Form from "react-bootstrap/Form";
import "./InventoryReport.css";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import {
  formatToHumanReadablePrice,
  calculateTurn
} from "../../utils/utils";

const InventoryReport = ({ isCsr }) => {
  // const SUMMARY_DATA_PROPERTIES = { 'lab': 'diamond#cert', 'shape': 'diamond#shape', 'color': 'diamond#color', 'clarity': 'diamond#clarity', 'carats': 'gem#carats', 'milky': 'diamond#milky', 'shade': 'diamond#shade', 'make': 'diamond#make', 'fluor': 'diamond#fluor', 'bic': 'diamond#black_in_center', 'bis': 'diamond#black_in_side', 'wis': 'diamond#white_in_side', 'wic': 'diamond#white_in_center' }
  // const ICON_HASH_FOR_SUMMERY = { 'Total Price': ['bi-card-checklist', 'sales-card'], 'Average Price': ['bi-currency-dollar', 'revenue-card'], 'Count': ['bi-people', 'customers-card'] }
  // const CENTS_TO_DOLLAR_CONVERSION_FACTOR = 100.00
  // const REPORT_VALUE_MAPPING = { 'STK': 'attr_types.diamond#stock_number', 'CERT': 'attr_types.diamond#cert_number', 'AGING': 'aging_group', 'SHP': 'attr_types.diamond#shape', 'COL': 'attr_types.diamond#color', 'CLAR': 'attr_types.diamond#clarity', 'CTS': 'attr_types.gem#carats', 'MILKY': 'attr_types.diamond#milky', 'SHADE': 'attr_types.diamond#shade', 'MAKE': 'attr_types.diamond#make', 'FLOUR': 'attr_types.diamond#fluor', 'BIC': 'attr_types.diamond#black_in_center', 'BIS': 'attr_types.diamond#black_in_side', 'WIC': 'attr_types.diamond#white_in_center', 'WIS': 'attr_types.diamond#white_in_side', 'LAB': 'attr_types.diamond#cert', 'RAP %': 'attr_types.diamond#rap_percent', '$/CT': 'attr_types.gem#price_per_carat', 'Total': 'sell_offer_cents' }
  // const REPORT_HASH = { 'download_inv_report': [true, 'Inventory Report'], 'download_sale_report': [false, 'Sale Report'], 'download_aging_report': [false, 'Aging Report'] }
  const DEFAULT_REPORT_DOWNLOAD_PARAMS = {
    end_date: Moment(new Date()).format("yyyy-MM-DD"),
    start_date: Moment().startOf('year').format("yyyy-MM-DD"),
    inventory_date: Moment(new Date()).format("yyyy-MM-DD"),
    seller_account_id: [],
  };

  const inv_date = Moment(new Date()).format("yyyy-MM-DD");
  const e_date = Moment(new Date()).format("yyyy-MM-DD");
  const s_date = Moment().startOf('year').format("yyyy-MM-DD");

  const hard_date = new Date("2022-11-02");

  const [state, setState] = useState({
    // DataisLoaded: false,
    itemsData: {},
    options: {},
    reportSummary: [
      {key: "Total Price", value: 0, sub_key: undefined, subValue: undefined}, 
      {key: "Average Price", value: 0, sub_key: undefined, subValue: undefined}, 
      {key: "Count", value: 0, sub_key: undefined, subValue: undefined}
    ],
    DataisLoaded: false,
    BreakdownDataLoaded: false,
    showAlert: false,
    filterHash: {
      size: 1,
      end_date: e_date,
      start_date: s_date,
      inventory_date: inv_date,
      show_mine: true,
      show_others: true,
      seller_account_id: [],
      get_filtered_data: true,
      item_filter: { discrete: {} },
      get_only_matching_count: true,
      change_breakdown: true,
      get_turn: false,
      is_sale_report: false,
    },
    change_breakdown: true,
    inventory_date: inv_date,
    end_date: e_date,
    start_date: s_date,
    before_inventory: false,
    rowFilter: {}, // Tracks the filter which needs to be applied on clicking a row in distribution table (Static Report)
    // inventory_date: '',
    get_turn: false,
    showDrillDown: false,
    download_inv_report: true,
    download_sale_report: false,
    download_aging_report: false,
    downloadReportMessage: "",
    downloadErrorAlert: false,
    downloadSuccessAlert: false,
  });

  //defining refs

  const initialItemData = useRef();
  const initialRankData = useRef();

  let can_change_breakdown = true;
  // let can_get_turn = false

  // let new_inventory_date = inv_date

  const handleShowDrillDown = () => {
    setState((prevState) => ({ ...prevState, showDrillDown: true }));
  };

  const handleHideDrillDown = () => {
    setState((prevState) => ({ ...prevState, showDrillDown: false }));
  };

  const captureReportDownloadParams = () => {
    let paramsHash = DEFAULT_REPORT_DOWNLOAD_PARAMS;
    paramsHash["seller_account_id"] = state.filterHash.seller_account_id;
    paramsHash["download_inv_report"] = state.download_inv_report;
    paramsHash["download_sale_report"] = state.download_sale_report;
    paramsHash["download_aging_report"] = state.download_aging_report;
    return paramsHash;
  };

  // const requestForReports = () => {
  // commented the logic, so when testing it does not send the report on email every time.
  // console.log("it is getting called.")
  // let accessToken = Cookies.get('access_token')
  // let queryParams = captureReportDownloadParams()
  // fetch(process.env.REACT_APP_API_URL + "/download_inventory_report", { method: 'POST', headers: { 'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json' }, body: JSON.stringify(queryParams) })
  //     .then(response => response.json())
  //     .then((response) => {
  //         if (response.error)
  //             setState((prevState) => ({
  //                 ...prevState,
  //                 downloadErrorAlert: true, downloadSuccessAlert: false, downloadReportMessage: response.message
  //             }))
  //         else
  //             setState((prevState) => ({
  //                 ...prevState,
  //                 downloadSuccessAlert: true, downloadErrorAlert: false, downloadReportMessage: response.message
  //             }))

  //     })
  //     .catch(() => {
  //         setState((prevState) => ({
  //             ...prevState,
  //             downloadErrorAlert: true, downloadSuccessAlert: false, downloadReportMessage: 'Something went Wrong, Please contact Administrator.'
  //         }))

  //     });
  // }

  const handleDownloadSuccess = (message) => {
    setState((prevState) => ({
      ...prevState,
      downloadSuccessAlert: true,
      downloadErrorAlert: false,
      downloadReportMessage: message,
    }));
  };

  const handleDownloadError = (message) => {
    setState((prevState) => ({
      ...prevState,
      downloadErrorAlert: true,
      downloadSuccessAlert: false,
      downloadReportMessage: message,
    }));
  };

  const handleDownloadRequestError = () => {
    setState((prevState) => ({
      ...prevState,
      downloadErrorAlert: true,
      downloadSuccessAlert: false,
      downloadReportMessage:
        "Something went Wrong, Please contact Administrator.",
    }));
  };

  // const handleCheckClick = (event) => {
  //     setState((prevState) => ({
  //         ...prevState,
  //         [event.target.name]: event.target.checked
  //     }))

  // }

  const generateOptions = (response) => {
    // if response is not blank then skip
    if(Object.keys(state.options).length === 0){
      Object.entries(response).map(([key, options]) =>
        options.map((data) => {
          data["className"] = key;
        })
      );
      setState((prevState) => ({
        ...prevState,
        options: response,
      }));  
    }
  };

  const getBreakdownData = () => {
    let accessToken = Cookies.get("access_token");
    captureUrls();
    let queryParams = state.filterHash;
    fetch(process.env.REACT_APP_API_URL + "/inventory_report", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryParams),
    })
      .then((response) => response.json())
      .then((response) => {
        generateOptions(response);
        setReportSummary(response);
        // if inventory_date is not today then we are setting reportSummary using data from any one breakdown data
        // We have also hidden changeBreakdown and made it true, so we will be getting breakdown data always
        // We can pause the call for reportSummary
        if (Object.values(response).every((element) => element.length === 0))
          setState((prevState) => ({
            ...prevState,
            showAlert: true,
          }));
        setState((prevState) => ({
          ...prevState,
          itemsData: response,
          DataisLoaded: true,
          BreakdownDataLoaded: true,
        }));

        if (queryParams.get_turn) {
          if (!queryParams.change_breakdown && !initialRankData.current) {
            initialRankData.current = response;
          }
        } else {
          if (!queryParams.change_breakdown && !initialItemData.current) {
            initialItemData.current = response;
          }
        }
      });
  };

  const setReportSummary = (catBreakdown) => {
    let reportSummary = state.reportSummary;
    let totalCount = catBreakdown[Object.keys(catBreakdown)[0]].reduce(
      (total, obj) => obj.count + total,
      0
    );
    let totalValue = catBreakdown[Object.keys(catBreakdown)[0]].reduce(
      (total, obj) =>
        parseInt(
          obj.total_amount.replace(/^\D+/g, "").replace(/,/g, "").split(".")[0]
        ) + total,
      0
    );
    let totalAverage = "$" + Math.round(totalValue / totalCount).toLocaleString("en-US")
    reportSummary = []
    let salesCount = 0
    let salesValue = 0
    let salesAverage = 0
    let turn = 0
    if(state.get_turn){
      salesCount = catBreakdown[Object.keys(catBreakdown)[0]].reduce((total, obj) => obj.sale_count + total,0);
      salesValue = catBreakdown[Object.keys(catBreakdown)[0]].reduce(
        (total, obj) =>
          parseInt(
            obj.sale_total_amount.replace(/^\D+/g, "").replace(/,/g, "").split(".")[0]
          ) + total,
        0
      );
      salesAverage = isNaN(salesValue / salesCount) ? "0" : "$" + Math.round(salesValue / salesCount).toLocaleString("en-US")
      let salesDuration = Math.round(
        (new Date(state.end_date) - new Date(state.start_date)) / (1000 * 60 * 60 * 24)
      )
      turn = calculateTurn(totalValue, salesValue, salesDuration)
      reportSummary.push({key: "Total Price", value: formatToHumanReadablePrice(totalValue), subKey: "Sales", subValue: formatToHumanReadablePrice(salesValue), turn: turn.toLocaleString("en-US")})
      reportSummary.push({key: "Average Price", value: totalAverage, subKey: "Sales", subValue: salesAverage, turn: undefined})
      reportSummary.push({key: "Count", value: totalCount.toLocaleString("en-US"), subKey: "Sales", subValue: salesCount.toLocaleString("en-US"), turn: undefined})
    }else{
      reportSummary.push({key: "Total Price", value: formatToHumanReadablePrice(totalValue), subKey: undefined, subValue: undefined})
      reportSummary.push({key: "Average Price", value: totalAverage, subKey: undefined, subValue: undefined})
      reportSummary.push({key: "Count", value: totalCount.toLocaleString("en-US"), subKey: undefined, subValue: undefined})
    }
    setState((prevState) => ({
      ...prevState,
      reportSummary: reportSummary,
    }));
  };

  // const handleBreakdownClick = (event) => {

  //     can_change_breakdown = event.target.checked
  //     setState((prevState) => ({
  //         ...prevState,
  //         change_breakdown: event.target.checked
  //     }))

  // }
  const handleTurnClick = (event) => {
    // console.log({ event: event.target.checked });
    setState((prevState) => ({
      ...prevState,
      get_turn: event.target.checked,
    }));
  };
  const handleTurnDateClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      inventory_date: event.target.value,
    }));
  };
  const handleStartDateClick = (event) => {
    setState((prevState) => {
      const newFilterHash = {
        ...prevState.filterHash,
        start_date: event.target.value
      };
      return {
        ...prevState,
        start_date: event.target.value,
        filterHash: newFilterHash
      };
    });
  };
  
  const handleEndDateClick = (event) => {
    setState((prevState) => {
      const newFilterHash = {
        ...prevState.filterHash,
        end_date: event.target.value
      };
      return {
        ...prevState,
        end_date: event.target.value,
        filterHash: newFilterHash
      };
    });
  };

  const captureUrls = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sellerIds, getTurn;
    let filterHash = state.filterHash;
    var sellerIdString = queryParams.get("sellerIds");
    if (sellerIdString) {
      sellerIds = sellerIdString.split(",");
      filterHash["seller_account_id"] = sellerIds;
    }
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));
  };

  const applyFilter = (filters) => {
    let filterHash = state.filterHash;
    filterHash["item_filter"]["discrete"] = filters;
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));

    if (moment(state.inventory_date).isAfter(moment(hard_date))) {
      calculateData();
    } else {
      // console.log("updated Alert");
      setState((prevState) => ({
        ...prevState,
        before_inventory: true,
        itemsData: {},
      }));
    }
  };

  const calculateData = (event) => {
    // let accessToken = Cookies.get('access_token')
    setState((prevState) => ({
      ...prevState,

      BreakdownDataLoaded: false,
    }));

    // let queryParams = this.serialize(state.filterHash)
    // Disabling below query as this is not needed anymore as we are getting the data from breakdown
    // fetch(process.env.REACT_APP_API_URL + "/items?"+ queryParams, {method: 'GET', headers: {'Authorization': 'Bearer '+ accessToken }})
    //   .then(response => response.json())
    //   .then((response) => {
    //     let reportSummary = state.reportSummary
    //     reportSummary['Count'] = response.meta.total
    //     reportSummary['Total Price'] = response.meta.aggregations.total_sum.slice(0,-3)
    //     reportSummary['Average Price'] = response.meta.aggregations.average.slice(0,-3)
    //     this.setState({
    //       reportSummary: reportSummary
    //     });
    //   })
    // TODO - Drilldown won't work if the inventory isn't current
    // Calculate BreakDown
    let filterHash = state.filterHash;
    let getBreakdown = true;

    if (state.get_turn) {
      if (state.inventory_date === "") {
        alert("Please select inventory Date");
        return 0;
      } else {
        // if (can_change_breakdown) {
        //     filterHash.change_breakdown = true
        //     getBreakdown = true
        // }
        // else {
        //     if (initialRankData.current) {
        //         setState((prevState) => ({
        //             ...prevState,
        //             itemsData: initialRankData.current,
        //             BreakdownDataLoaded: true
        //         }))
        //         getBreakdown = false
        //     }
        //     else {
        //         filterHash = state.filterHash
        //         getBreakdown = true
        //     }
        //     filterHash.change_breakdown = false
        // }
        filterHash.inventory_date = state.inventory_date;
        filterHash.get_turn = true;
      }
    } else {
      filterHash.get_turn = false;
      filterHash.inventory_date = state.inventory_date;
      // if (can_change_breakdown) {
      //     filterHash.change_breakdown = true
      //     getBreakdown = true
      // }
      // else {
      //     if (initialItemData.current) {
      //         setState((prevState) => ({
      //             ...prevState,
      //             itemsData: initialItemData.current, BreakdownDataLoaded: true
      //         }))

      //         getBreakdown = false
      //     }
      // }
    }
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));

    if (getBreakdown) getBreakdownData();
  };

  // const onSelect = (selectedList, removedItem) => {
  //     var filterValues = []
  //     var attrName = null
  //     if (selectedList.length == 0) {
  //         attrName = SUMMARY_DATA_PROPERTIES[removedItem.className]
  //     }
  //     Object.entries(selectedList).map(([key, option]) => {
  //         attrName = SUMMARY_DATA_PROPERTIES[option.className]
  //         if (attrName) {
  //             if (attrName === "gem#carats") {
  //                 let carat_range = option.key.split('-')
  //                 filterValues.push({ 'to': carat_range[0], 'from': carat_range[1] })
  //             }
  //             else {
  //                 filterValues.push(option.key)
  //             }
  //         }
  //     })
  //     let filterHash = state.filterHash
  //     filterHash['item_filter']['discrete'][attrName] = filterValues.flat()
  //     setState((prevState) => ({
  //         ...prevState,
  //         filterHash: filterHash
  //     }))

  // }

  const handleBeforeAlertClose = () => {
    setState((prevState) => ({
      ...prevState,
      before_inventory: false,
    }));
  };

  useEffect(() => {
    calculateData();
  }, []);

  // console.log(state)
  const {
    DataisLoaded,
    showDrillDown,
    downloadReportMessage,
    downloadErrorAlert,
    downloadSuccessAlert,
  } = state;
  const getTurn = state.filterHash.get_turn;
  const showInventoryDateInput = true;
  let noRecords = Object.values(state.itemsData).every(
    (element) => element.length == 0
  );
  let showAlert = noRecords && state.showAlert;
  let drilldownClass = showDrillDown ? "show" : "";
  let drilldownStyle = { display: showDrillDown ? "block" : "none" };
  return (
    <>
      {!DataisLoaded ? (
        <div className="row">
          <div className="align-items-center justify-content-center">
            <h3 className=" text-center"> Fetching reports for you.... </h3>
          </div>
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
        </div>
      ) : (
        <div
          className="row justify-content-start border-secondary"
          style={{ margin: "10px" }}
        >
          {/*        <div className="p-5 text-center">
                            <h3 className="mb-3">Inventory Report</h3>
                            </div>
                    */}
          <br></br>
          {state.before_inventory ? (
            <div
              className="alert alert-warning alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              {/* Oops, we couldn’t find any data. Please try with different some filters or params.
                            
                            */}
              You don't have any inventory on Liquid Diamonds before{" "}
              {state.inventory_date}
              {/* TODO: change the state.inventory to the backend invertory date */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={handleBeforeAlertClose}
              ></button>
            </div>
          ) : null}

          {showAlert ? (
            <div
              className="alert alert-warning alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              Oops, we couldn’t find any data. Please try with different some
              filters or params.
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}

          {downloadErrorAlert ? (
            <div
              className="alert alert-danger alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              {downloadReportMessage}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}

          {downloadSuccessAlert ? (
            <div
              className="alert alert-success alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              {downloadReportMessage}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}

          {/* <Filter filterHash={state.filterHash} options={state.options} noRecords={noRecords} showInventoryDateInput={showInventoryDateInput} inventory_date={state.inventory_date
                    } /> */}

          <Accordion defaultActiveKey="0">
            <Accordion.Item
              eventKey="0"
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <Accordion.Header
                style={{
                  borderRadius: "4px 4px 0 0",
                  border: "1px solid rgba(0, 0, 0, 0.175)",
                  // marginBottom: "10px"
                }}
              >
                Filters
              </Accordion.Header>
              <Accordion.Body
                style={{
                  padding: "0",
                  margin: "0",
                }}
              >
                <div
                  className="filters card multiselect-dropdown"
                  style={{
                    marginBottom: "0px",
                    borderRadius: "0px 0px 4px 4px",
                  }}
                >
                  <div className="filterHeader">
                    <div>
                      {showInventoryDateInput ? (
                        <div className="row">
                          <div className="col-3 col-md-4 col-lg-4">
                            <label
                              style={{
                                marginLeft: "20px",
                              }}
                              htmlFor="inputDate"
                              className="col-form-label"
                            >
                              Date:
                            </label>
                          </div>
                          <div className="col-9 col-md-8 col-lg-8">
                            <input
                              type="date"
                              className="form-control"
                              value={state.inventory_date}
                              dateformat="dd/MM/yyyy"
                              onChange={handleTurnDateClick}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-wrap g-3">
                      {/* Compare with Sales Switch */}
                      <div className="me-4">
                        <Form>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Compare with Sales"
                            onChange={handleTurnClick}
                          />
                        </Form>
                      </div>

                      {/* Conditional Rendering of Start Date and End Date */}
                      {true && (
                        <>
                          <div className="me-2">
                            <label htmlFor="inputStartDate" className="col-form-label">
                              Start Date
                            </label>
                          </div>
                          <div className="me-4">
                            <input
                              type="date"
                              id="inputStartDate"
                              className="form-control"
                              value={state.start_date}
                              dateFormat="dd/MM/yyyy"
                              onChange={handleStartDateClick}
                              disabled={!state.get_turn}
                            />
                          </div>
                          <div className="me-2">
                            <label htmlFor="inputEndDate" className="col-form-label">
                              End Date
                            </label>
                          </div>
                          <div>
                            <input
                              type="date"
                              id="inputEndDate"
                              className="form-control"
                              value={state.end_date}
                              dateFormat="dd/MM/yyyy"
                              onChange={handleEndDateClick}
                              disabled={!state.get_turn}
                            />
                          </div>
                        </>
                      )}
                    </div>



                    {/* <div className="row form-check form-switch multiselect-dropdown">

                                <div className=' col-sm-2'>

                                    <label className="form-check-label" htmlFor="turnSwitch">Compare with Sales</label>
                                    <input className="form-check-input" type="checkbox" id="turnSwitch" name='get_turn' value={state.filterHash.get_turn} onChange={handleTurnClick} />
                                </div>
                            </div> */}
                  </div>

                  <Filter
                    options={state.options}
                    noRecords={noRecords}
                    applyFilter={applyFilter}
                    handleDownloadSuccess={handleDownloadSuccess}
                    handleDownloadError={handleDownloadError}
                    handleDownloadRequestError={handleDownloadRequestError}
                    downloadParams={captureReportDownloadParams}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <hr
            style={{
              marginTop: "20px",
            }}
          />

          {noRecords ? null : (
            <div className="col-lg-12 dashboard">
              <div className="row">
                {Object.entries(state.reportSummary).map(([index, summary]) => {
                  return (
                    <SummaryCard
                      key={summary.key}
                      summaryKey={summary.key}
                      value={summary.value}
                      handleShowDrillDown={handleShowDrillDown}
                      showDrops={true}
                      headerSubText="Inventory"
                      subKey={summary.subKey}
                      subValue={summary.subValue}
                      turn={summary.turn}
                    />
                  );
                })}
              </div>

              <div
                className={"modal fade " + drilldownClass}
                id="fullscreenModal"
                style={drilldownStyle}
              >
                <div
                  className="modal-dialog modal-fullscreen"
                  style={{ width: "100%" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="pagetitle">
                        <h1>My Inventory</h1>
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={handleHideDrillDown}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        {Object.entries(state.reportSummary).map(
                          ([index, summary]) => {
                            return (
                              <SummaryCard
                                key={summary.key}
                                summaryKey={summary.key}
                                value={summary.value}
                                handleShowDrillDown={handleShowDrillDown}
                                showDrops={false}
                                headerSubText="Inventory"
                                subKey={summary.subKey}
                                subValue={summary.subValue}
                                turn={summary.turn}
                              />
                            );
                          }
                        )}
                      </div>
                      <div className="drilldown">
                        {drilldownClass ? (
                          <AgingReport
                            isCsr={isCsr}
                            filterHash={state.filterHash}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
            </div>
          )}
          {noRecords ? null : (
            <div className="text-center">
              {state.get_turn ? (
                <h5>
                  Showing Inventory breakdown as on {state.inventory_date} along
                  with <strong>{
                  Math.round(
                    (new Date(state.end_date) - new Date(state.start_date)) / (1000 * 60 * 60 * 24)
                  )
                }{" "}</strong> days sales
                </h5>
              ) : (
                <h5>
                  Showing Inventory breakdown as on {state.inventory_date}
                </h5>
              )}
              <br></br>
            </div>
          )}
          {noRecords ? null : (
            <div>
              {getTurn ? (
                <TurnReport
                  items={state.itemsData}
                  DataisLoaded={state.BreakdownDataLoaded}
                  setState={setState}
                  filterHash={state.filterHash}
                  rowFilter={state.rowFilter}
                />
              ) : (
                <StaticReport
                  items={state.itemsData}
                  DataisLoaded={state.BreakdownDataLoaded}
                  setState={setState}
                  filterHash={state.filterHash}
                  rowFilters={state.rowFilter}
                  id="inventory"
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InventoryReport;
