import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  columnDef,
  getFilteredRowModel,
  getFacetedRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import CellContainer from "./cellContainer";
import amplitude from "../../utils/ampli";
import TablePagination from "@mui/material/TablePagination";

import {
  addOrReduceDecimalPlace,
  clarityValues,
  cn,
  colorPalette,
  colorValues,
  compareAttributes,
  convertTimestampToReadableDate,
  cutValues,
  debounce,
  filterValues,
  fluorescenceValues,
  formatter,
  isValueInRange,
  mapping,
  milkyValues,
  polishValues,
  shadeValues,
  sortMapping,
  strToInt,
  symmetryValues,
} from "../../utils/utils";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  pricing,
  setAnchorData,
  setItemStore,
  setSelectedItem,
  setUpdatedColumnOrder,
  setUserTable,
  setDiamondRepriced,
  setSelectedDiamondRow,
  setBroadCount,
} from "../../redux/slices/pricingSlice";
import PricingPopOver from "../pricingPopOver";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../redux/slices/authSlice";
import {
  GripVertical,
} from "lucide-react";
import LdRecodiff from "./cellComponents/ldRecodiff";
import Aging from "./cellComponents/aging";
import RapCount from "./cellComponents/rapCount";
import { apiSlice } from "../../redux/slices/apiSlice";
import LdReco from "./cellComponents/ldReco";
import RapRank from "./cellComponents/rapRank";
import Comment from "./cellComponents/comment";
import {
  BsCaretUp,
  BsCaretDown,
  BsCaretUpFill,
  BsCaretDownFill,
} from "react-icons/bs";
import { FaCamera, FaLockOpen } from "react-icons/fa";
import { useDrag, useDrop } from "react-dnd";
import CellContainerWithRef from "./cellContainerWithRef";
import RepricingCell from "./cellComponents/repricingCell";
import RapChangeCell from "./cellComponents/rapChangeCell";
import MarketDemandSupplyCell from "./cellComponents/marketDemandSupplyCell";
import ExpandedFilter from "./cellComponents/expandedFilter";
import TableLockFilter from "./cellComponents/tableLockFilter";
import Logs from "./cellComponents/logs";


const SelectionCheckbox = React.memo(({ isSelected, onChange, isAllSelected, isHeader }) => {
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up to the row
    onChange(e);
  };
  
  return (
    <input
      type="checkbox"
      checked={isSelected}
      onChange={handleClick}
      className="cursor-pointer"
    />
  );
});


const PriceTable = ({
  data,
  rowHeight,
  trClassName,
  className,
  hideHeader = false,
  comparison = false,
  isRepricing,
  currentPage,
  setCurrentPage,
  handleOnSort,
  handleOnSort2,
  sorting,
  isFetching,
  currentData,
  setPageSize,
  pageSize,
  sortState,
  filterLoading,
  onSelectionChange,
  
}) => {
  const columnHelper = createColumnHelper();
  
  const tableRef = useRef(null);
  const location = useLocation();
  const selectedItem = useSelector(pricing).selectedItem;
  const itemStore = useSelector(pricing).itemStore;
  const diamondRepriced = useSelector(pricing).diamondRepriced;
  // const diamondRepriced = useSelector(pricing => pricing.diamondRepriced);
  const selectedDiamondRow = useSelector(pricing).selectedDiamondRow;
  const isBroadCount = useSelector(pricing).isBroadCount;
  const [filtered, setFiltered] = useState([{ id: "State", value: ""}]);

  useEffect(() => {
    setFiltered((prev) => {
      const index = prev.findIndex((item) => item?.id === "State");
      if (index !== -1) {
        prev[index] = { id: "State", value: isRepricing ? "repriced" : "" };
        return [...prev];
      }
      return prev;
    });
  }, [isRepricing]);

  let navigate = useNavigate();

  const accountDetails = useSelector(auth).userDetails;
  let token = useSelector((state) => state.auth.token);
  const selectedRowRef = useRef(); // Creating a reference for the row (tr) to scroll into view
  // Function to scroll the selected row into view
  const scrollToSelectedRow = () => {
    if (selectedRowRef.current) {
      selectedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (location.state?.selectedRowId) {
      setSelectedRowId(location.state.selectedRowId);
    }
  }, [location]);

  const [selectedRowId, setSelectedRowId] = useState(location.state?.selectedRowId || null);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const selectColumn = useMemo(() => 
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <SelectionCheckbox
          isSelected={allSelected}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setAllSelected(isChecked);
            setSelectedItems(isChecked ? data : []);
          }}
          isHeader={true}
        />
      ),
      cell: ({ row }) => (
        <SelectionCheckbox
          isSelected={selectedItems.some(item => item["LD ID"] === row.original["LD ID"]) || allSelected}
          onChange={(e) => {
            setSelectedItems(prev => 
              e.target.checked
                ? [...prev, row.original]
                : prev.filter(item => item["LD ID"] !== row.original["LD ID"])
            );
            setAllSelected(false);
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    }),
  [currentData, allSelected, selectedItems]);
  
  const otherColumns = useMemo(
    () => [
      columnHelper.accessor("Stock No", {
        id: "Stock Num",
        header: "Stock Num", // Added header
        cell: (info) => {
          if (
            info.row.original["Stock No"] == null ||
            info.row.original["Stock No"] == ""
          )
            return info.row.original["Cert Num"];

          return info.row.original["Stock No"];
        },
        filterFn: "filter",
      }),
      columnHelper.accessor("Shape", {
        id: "Shape",
        header: "Shape", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Carat", {
        id: "Carat",
        header: "Carat", // Added header
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("Color", {
        id: "Color",
        header: "Color", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(selectedItem, info, "Color", colorValues);
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Clarity", {
        id: "Clarity",
        header: "Clarity", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(
              selectedItem,
              info,
              "Clarity",
              clarityValues
            );
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Cut", {
        id: "Cut",
        header: "Cut", // Added header
        cell: (info) => {
          if (
            info.row.original["Special Cut"] !== null &&
            info.row.original["Shape"].toLowerCase() !== "round"
          ) {
            return (
              <p className="text-green-500 m-0 p-0">
                {info.row.original["Special Cut"]}
              </p>
            );
          }
          // } else if (comparison) {
          //   return compareAttributes(selectedItem, info, "Cut", cutValues);
          // }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Polish", {
        id: "Polish",
        header: "Polish", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(
              selectedItem,
              info,
              "Polish",
              polishValues
            );
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Sym", {
        id: "Sym",
        header: "Sym", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(selectedItem, info, "Sym", symmetryValues);
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Fluor", {
        id: "Fluor",
        header: "Fluor", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(
              selectedItem,
              info,
              "Fluor",
              fluorescenceValues
            );
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Lab", {
        id: "Lab",
        header: "Lab", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("My YTD Sales", {
        id: "My YTD Sales",
        header: () => (
          <Tooltip title="Number of stones sold from your inventory since beginning of the year">
            <span>My YTD Sales</span>
          </Tooltip>
        ),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("Rap $/Ct", {
        id: "Rap $/Ct",
        header: "Rap $/Ct", // Added header
        cell: (info) => {
          const value = strToInt(info.getValue())?.toFixed(0);
          return formatter.format(Math.round(value));
          // return(
          //   <div className="text-right font-mono">
          //     {formatter.format(Math.round(info.getValue()))}
          //   </div>
          // );
        },
      }),
      columnHelper.accessor("Rap Total", {
        id: "Rap Total",
        header: "Rap Total", // Added header
        cell: ({ row }) => {
          const rap_total = Math.round(row.original["Rap $/Ct"] * row.original["Carat"]);
          return formatter.format(Math.round(rap_total));
          // return(
          //   <div className="text-right font-mono">
          //     {formatter.format(Math.round(rap_total))}
          //   </div>
          // );
        },
      }),
      columnHelper.accessor("Rap %", {
        id: "Rap %",
        header: "Rap %",
        cell: (info) => {
          const value = strToInt(info.getValue());

          const data = {
            value: value,
            item_id: info.row.original["LD ID"],
            is_total_price: false,
            is_price_per_carat: false,
            is_rap_discount: true,
          };

          return (
            <RepricingCell
              info={info}
              table={info.table}
              data={data}
              fieldName={"Rap %"}
              isFormatted={false}
            />
          );
        },
      }),
      columnHelper.accessor("$/Ct", {
        id: "$/Ct",
        // cell: ({ row, getValue }) => {
        //   return formatter.format(Math.round(getValue()));
        // },
        cell: (info) => {
          const value = strToInt(info.getValue())?.toFixed(0);

          const data = {
            value: value,
            item_id: info.row.original["LD ID"],
            is_total_price: false,
            is_price_per_carat: true,
            is_rap_discount: false,
          };

          return (
            <RepricingCell
              info={info}
              table={info.table}
              data={data}
              fieldName={"$/Ct"}
              isFormatted={true}
            />
          );
        },
      }),

      columnHelper.accessor("Total Price", {
        id: "Total Price",
        cell: function Cell(info) {
          const [value, setValue] = useState(
            strToInt(info.getValue())?.toFixed(0)
          );

          useEffect(() => {
            setValue(strToInt(info.getValue())?.toFixed(0));
          }, [info]);

          const data = {
            value: value,
            item_id: info.row.original["LD ID"],
            is_total_price: true,
            is_price_per_carat: false,
            is_rap_discount: false,
          };

          return (
            <RepricingCell
              info={info}
              table={info.table}
              data={data}
              fieldName={"Total Price"}
              isFormatted={true}
            />
          );
        },
      }),

      columnHelper.accessor("Cost $/Ct", {
        id: "Cost $/Ct",
        header: "Cost $/Ct", // Added header
        cell: ({row, getValue}) => {
          const cost_dollar_per_carat = Math.round(row.original["Cost $/Ct"]);
          return (typeof getValue() === "number"
            ? formatter.format(cost_dollar_per_carat)
            : "-")
        },
        filterFn: "singleValueFilter",
      }),

      columnHelper.accessor("aging", {
        id: "Aging",
        header: "Aging",
        cell: ({ row, getValue }) => getValue(),
        // cell: ({ row, getValue }) => {
        //   return(
        //     <div className="text-right font-mono">
        //       {getValue()}
        //     </div>
        //   );
        // },
      }),

      columnHelper.accessor("Reco Rap", {
        id: "Use Rap",
        header: "Repl Rap",
        cell: ({ row, getValue }) =>
          typeof getValue() === "number"
            ? parseFloat(getValue()).toFixed(1)
            : "-",
        // cell: ({ row, getValue }) => {
        //   const recoRap = (typeof getValue() === "number") ? parseFloat(getValue()).toFixed(1) : "-";
        //   return(
        //     <div className="text-right font-mono">
        //       {recoRap}
        //     </div>
        //   );
        // }
      }),
      columnHelper.accessor("Reco PPC", {
        id: "Use Price",
        header: "Repl PPC",
        cell: ({ row, getValue }) =>
          typeof getValue() === "number"
            ? formatter.format(getValue())
            : "-",
      }),
      columnHelper.accessor("LD Reco Diff", {
        id: "LD Reco Diff",
        header: "Repl Diff",
        cell: ({ row, getValue }) => {
          const rap = row.original["Rap %"];
          const LDR = row.original["LD Reco"];

          if (isNaN(parseFloat(LDR)) || isNaN(parseFloat(rap))) return "";

          if (rap != null || (rap !== "" && LDR != null) || LDR !== "") {
            const x = addOrReduceDecimalPlace(
              parseFloat((LDR - rap).toFixed(1))
            );

            return <div>{x}</div>;
          }
          return "";
        },
      }),

      // Hide 'LD Rank' and 'LD Count'
      // columnHelper.accessor("LD Rank", {
      //   id: "LD Rank",
      //   header: "LD Rank",
      //   cell: ({ row, getValue }) => {
      //     return getValue();
      //   },
      // }),
      // columnHelper.accessor("LD Count", {
      //   id: "LD Count",
      //   header: "LD Count",
      //   cell: ({ row, getValue }) => getValue(),
      // }),
      columnHelper.accessor("LD Spec", {
        id: "LD Spec",
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Rap Rank", {
        id: "Rap Rank",
        header: () => (
          <Tooltip title="Your Rank against the competition">
            <span>Rank</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => {
          if (getValue() == null) {
            return "--";
          }

          if (row.original["Stock No"] == "1.00W822058") {
          }
          return parseInt(getValue());
        },
        sortingFn: "rankSort",
        footer: (item) => item.column.id,
      }),

      columnHelper.accessor("Rap Matching count", {
        id: "Rap Matching count",
        header: () => (
          <Tooltip title="Number of stones in the market similar to your stone">
            <span>Count</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => {
          return getValue();
          // return(
          //   <div className="text-right font-mono">
          //     {getValue()}
          //   </div>
          // );
        },
        footer: (item) => item.column.id,
      }),

      columnHelper.accessor("Rap Rank Diff", {
        id: "Rap Rank Diff",
        header: () => (
          <Tooltip title="Change in rank since you last re-priced the stone or locked the stone">
            <span>Rank Change</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Rap Count Diff", {
        id: "Rap Count Diff",
        header: () => (
          <Tooltip title="Change in number of stones in the market">
            <span>Count Change</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => (getValue() > 0 ? ('+' + getValue()) : getValue()),
      }),
      columnHelper.accessor("Rank Change (30 D)", {
        id: "Rank Change (30 D)",
        header: () => (
          <Tooltip title="Change in rank in last 30 days">
            <span>Rank Change (30 D)</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Count Change (30 D)", {
        id: "Count Change (30 D)",
        header: () => (
          <Tooltip title="Change in number of stones in the market in last 30 days">
            <span>Count Change (30 D)</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => (getValue() > 0 ? ('+' + getValue()) : getValue()),
      }),

      // columnHelper.accessor("Rap Cluster Count", {
      //   id: "Rap Cluster Count",
      //   header: "Cluster Count",
      //   cell: ({ row, getValue }) => getValue(),
      // }),

      columnHelper.accessor("Inventory Count", {
        id: "Inventory Count",
        header: () => (
          <Tooltip title="Number of similar stones in your inventory">
            <span>My Stock</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Sales Count", {
        id: "Sales Count",
        header: () => (
          <Tooltip title="Number of stones sold from your inventory in the last 30 days">
            <span>My Sales</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Supply Count", {
        id: "Added",
        // header: "Added",
        header: () => (
          <Tooltip title="Number of stones added in the market since you last re-priced the stone or locked the stone">
            <span>Added</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("trackingDate", {
        id: "Tracking Date",
        header: () => (
          <Tooltip title="Date since you last re-priced the stone or locked the stone">
            <span>Tracking Date</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => convertTimestampToReadableDate(getValue()),
      }),

      columnHelper.accessor("totalPrmiCount", {
        id: "Total Availability",
        header: () => (
          <Tooltip title="Number of stones in the market with broader criteria">
            <span>Broad Count</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => (
          <div 
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click event
              dispatch(setBroadCount(true));
              handleRowClick(row.original);
            }}
            className=" cursor-pointer py-2 pt-2 pr-4 pl-7 h-full w-full"
          >
            {getValue()}
          </div>
        ),
      }),

      columnHelper.accessor("Demand Count", {
        id: "Sold",
        header: () => (
          <Tooltip title="Number of stones removed from the market since you last re-priced the stone or locked the stone">
            <span>Sold</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Added (30 D)", {
        id: "Added (30 D)",
        header: () => (
          <Tooltip title="Number of stones added in the market in last 30 days">
            <span>Added (30 D)</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Sold (30 D)", {
        id: "Sold (30 D)",
        header: () => (
          <Tooltip title="Number of stones removed from the market in last 30 days">
            <span>Sold (30 D)</span>
          </Tooltip>
        ),
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Measurements", {
        id: "Measurements",
        header: "Measurements", // Added header
        cell: (info) => info.getValue(),
        filterFn: "measurementFilter",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("Ratio", {
        id: "Ratio",
        header: "Ratio",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Table %", {
        id: "Table",
        header: "Table", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Depth %", {
        id: "Depth",
        header: "Depth", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Key To Symbol", {
        id: "Key To Symbol",
        header: "Key To Symbols", // Added header
        cell: function Cell({ getValue, row, column, table }) {
          const value = getValue();

          if (value == null || value == "") {
            return "";
          }

          const ktsArray = value.split(",");

          const [array1, array2] = [
            ktsArray.slice(0, ktsArray.length / 2),
            ktsArray.slice(ktsArray.length / 2),
          ];

          return (
            <div className="flex flex-col justify-start items-start gap-1 overflow-x-scroll no-scrollbar whitespace-nowrap w-full">
              <div>
                {array1.map((item) => {
                  const color = colorPalette.get(item.trim().toLowerCase());

                  // console.log({
                  //   color,
                  //   item,
                  // });
                  return (
                    <Chip
                      label={item.toUpperCase()}
                      key={item.trim().toLowerCase()}
                      className="text-[0.625rem] h-4 font-bold  whitespace-nowrap mr-1 tracking-wide"
                      style={{
                        backgroundColor: "transparent",
                        color: color.foreground,
                      }}
                    />
                  );
                })}
              </div>
              <div>
                {array2.map((item) => {
                  const color = colorPalette.get(item.trim().toLowerCase());

                  return (
                    <Chip
                      label={item.toUpperCase()}
                      key={item.trim().toLowerCase()}
                      className=" text-[0.625rem] h-4 font-bold  whitespace-nowrap mr-1 tracking-wide"
                      style={{
                        // backgroundColor: color.background,
                        background: "transparent",
                        color: color.foreground,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          );
        },
        filterFn: "multiValueFilter", // Added filterFn
      }),
      columnHelper.accessor("Cert Comment", {
        id: "Cert Comments",
        header: "Cert Comments", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Girdle", {
        id: "Girdle",
        header: "Girdle", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Shade", {
        id: "Shade",
        header: "Shade", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(selectedItem, info, "Shade", shadeValues);
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Milky", {
        id: "Milky",
        header: "Milky", // Added header
        cell: (info) => {
          if (comparison) {
            return compareAttributes(selectedItem, info, "Milky", milkyValues);
          }

          return info.getValue();
        },
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Location", {
        id: "Location",
        header: "Location", // Added header
        cell: (info) => {
          // if (comparison) {
          //   return compareAttributes(selectedItem, info, "Location", milkyValues);
          // }

          return info.getValue();
        },
        // filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Eye Clean", {
        id: "Eye Clean",
        header: "Eye Clean", // Added header
        cell: (info) => {
          return info.getValue();
        },
      }),
      columnHelper.accessor("BIC", {
        id: "BIC",
        header: "BIC", // Added header
        cell: (info) => {
          return info.getValue();
        },
      }),

      columnHelper.accessor("BIS", {
        id: "BIS",
        header: "BIS",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("WIS", {
        id: "WIS",
        header: "WIS",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("WIC", {
        id: "WIC",
        header: "WIC",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("OT", {
        id: "OT",
        header: "OT",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("OC", {
        id: "OC",
        header: "OC",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("OP", {
        id: "OP",
        header: "OP",
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Crown Angle", {
        id: "Crown Angle",
        header: "Crown Angle",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Crown Height", {
        id: "Crown Height",
        header: "Crown Height",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Pav Angle", {
        id: "Pav Angle",
        header: "Pav Angle",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Pav Depth", {
        id: "Pav Depth",
        header: "Pav Depth",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Girdle Con", {
        id: "Girdle Con",
        header: "Girdle Con",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Culet", {
        id: "Culet",
        header: "Culet",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Flr Clr", {
        id: "Flr Clr",
        header: "Flr Clr",
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Created_At", {
        id: "Report Date",
        header: "Last Fetched Date",
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Carat Range", {
        id: "Carat Range",
        header: "Carat Range",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("State", {
        id: "State",
        header: "State", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Repriced_At", {
        id: "Repriced_At",
        header: "Repriced Date", // Added header
        cell: (info) => {
          if (info.getValue()) {
            return convertTimestampToReadableDate(info.getValue());
          }
          return "";
        },
      }),
    ],
    [currentData]
  );

  const columns = useMemo(() => [selectColumn, ...otherColumns], [selectColumn, otherColumns]);


  const [selectedField, setSelectedField] = useState({
    columnId: null,
    rowId: null,
  });

  const colOrder = useSelector(pricing).config?.column_orders;

  const filter = (row, columnId, values) => {
    if (values == "") return true;

    let v1 = row.original["Stock Num"];
    let v2 = row.original["Cert Num"];

    if (v1 !== undefined && v1 !== null) {
      v1 = v1.toString().toLowerCase();
    } else {
      v1 = "";
    }

    if (v2 !== undefined && v2 !== null) {
      v2 = v2.toString().toLowerCase();
    } else {
      v2 = "";
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    if (v1 !== "" && v2 !== "") {
      const foundInArray1 = values.some((value) =>
        v1.includes(value.toLowerCase())
      );
      const foundInArray2 = values.some((value) =>
        v2.includes(value.toLowerCase())
      );
      return foundInArray1 || foundInArray2;
    } else if (v1 !== "") {
      const foundInArray1 = values.some((value) =>
        v1.includes(value.toLowerCase())
      );
      return foundInArray1;
    } else if (v2 !== "") {
      const foundInArray2 = values.some((value) =>
        v2.includes(value.toLowerCase())
      );
      return foundInArray2;
    } else {
      return false;
    }
  };

  const totalItemCount = useSelector(pricing).totalCount;

  const colArr = ["Carat", "Color", "Clarity", "Cut", "Polish", "Sym", "Fluor"];

  // console.log({

  //   columns,
  // });

  // console.log({
  //   colOrder,
  // });

  const [columnOrder, setColumnOrder] = useState(
    colOrder != null || colOrder != undefined
      ? ["select", ...colOrder.filter(col => col !== "select")]
      : ["select", ...columns.map((column) => column.id).filter(id => id !== "select")]
  );

  

  useEffect(() => {
    let x = colOrder != null || colOrder != undefined 
    ? ["select", ...colOrder.filter(col => col !== "select")] 
    : ["select", ...columns.map((column) => column.id).filter(id => id !== "select")];
    setColumnOrder(x);
  }, [colOrder]);

  useEffect(() => {
    scrollToSelectedRow();
  }, [selectedRowRef.current]); // Run once after initial render

  const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
    const buttonClickedEvent = {
      event_type: `v2_pricetable_reorder`,
      event_properties: {
        draggedColumnId: draggedColumnId,
        targetColumnId,
        columnOrder,
      },
    };
    amplitude.track(buttonClickedEvent);
    const newOrder = columnOrder.filter(col => col !== "select");
    const dragIndex = newOrder.indexOf(draggedColumnId);
  const targetIndex = newOrder.indexOf(targetColumnId);

  if (dragIndex !== -1 && targetIndex !== -1) {
    const [removed] = newOrder.splice(dragIndex, 1);
    newOrder.splice(targetIndex, 0, removed);
  }

  return ["select", ...newOrder];
  };

  const resetOrder = () => setColumnOrder(columns.map((column) => column.id));

  const saveReorder = async (columnOrder) => {
    const url = `${process.env.REACT_APP_API_URL}/update_pricing_report_market_config`;

    const data = {
      seller_account_id: accountDetails.account_id,
      column_orders: columnOrder,
    };

    const res = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const DraggableColumnHeader = ({ header, table, headerGroup }) => {
    const { getState, setColumnOrder } = table;
    const { columnOrder } = getState();
    const { column } = header;

    const [, dropRef] = useDrop({
      accept: "column",
      drop: (draggedColumn) => {
        if (column.id !== "select" && draggedColumn.id !== "select") {
          const newColumnOrder = reorderColumn(
            draggedColumn.id,
            column.id,
            columnOrder
          );
          setColumnOrder(newColumnOrder);
          saveReorder(newColumnOrder);
          dispatch(setUpdatedColumnOrder(newColumnOrder));
        }
      },
    });

    const [hover, setHover] = useState(false);

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => column,
      type: "column",
      canDrag: () => column.id !== "select",
    });

    const handleOnMouseIn = () => setHover(true);
    const handleOnMouseOut = () => setHover(false);

    const handleClick = (e) => {
      if (header.id === "select") {
          e.stopPropagation();
      }
  };

    const isVisible = (id) => {
      return sortState.some(
        (item) => item.sort_by === id && item.sorting === true
      );
    };

    const isAsc = (id) => {
      return sortState.some(
        (item) =>
          item.sort_by === id && item.sorting === true && item.asc_desc == "asc"
      );
    };

    const isDesc = (id) => {
      return sortState.some(
        (item) =>
          item.sort_by === id &&
          item.sorting === true &&
          item.asc_desc == "desc"
      );
    };

    function findIndexByKeyValuePair(array, key, value) {
      return array.findIndex((obj) => obj[key] === value);
    }

    function isMarketColumn(header){
      return ["Rap Rank Diff", "Rap Rank", "Rap Matching count", "Total Availability", "Rap Count Diff", "Added", "Sold", "Rank Change (30 D)", "Count Change (30 D)", "Added (30 D)", "Sold (30 D)"].includes(header.id)
    }
    function isInventoryColumn(header){
      return ["Inventory Count", "Sales Count", "My YTD Sales", "Aging"].includes(header.id)
    }
    return (
      <>
        <th
          key={header.id}
          colSpan={header.colSpan}
          ref={dropRef}
          style={{ opacity: isDragging ? 0.5 : 1 }}
          className={cn("text-center relative whitespace-nowrap h-12 ", {
             "select-column": header.id === "select",
          })}
          onMouseEnter={handleOnMouseIn}
          onMouseLeave={handleOnMouseOut}
          onClick={handleClick}
        >
          <div className={`w-full h-full relative ${isInventoryColumn(header) ? "inventoryCellBorder" : ""} ${isMarketColumn(header) ? "marketCellBorder" : ""}`} ref={previewRef}>
            {findIndexByKeyValuePair(
              sortState,
              "sort_by",
              sortMapping[header.id]
            ) > -1 ? (
              <div
                className="absolute top-0 left-0 cursor-pointer "
                ref={dragRef}
              >
                <div className="bg-green-500 rounded-full px-1 w-3 h-3 flex justify-center items-center">
                  <p className="m-0 p-0 text-[0.56rem] text-white">
                    {findIndexByKeyValuePair(
                      sortState,
                      "sort_by",
                      sortMapping[header.id]
                    ) + 1}
                  </p>
                </div>
              </div>
            ) : null}

            <CellContainerWithRef
              className={cn(
                "justify-center relative ",
                colArr.includes(header.id) ? "!min-w-[45px]" : ""
              )}
              headerId={header.id}
            >
              <div
                className={cn(
                  "absolute top-1 cursor-pointer",
                  hover || isVisible(sortMapping[header.id])
                    ? "block"
                    : "hidden"
                )}
              >
                {sortMapping[header.id] ? (
                  <div className="flex justify-center items-center">
                    {isAsc(sortMapping[header.id]) ? (
                      <BsCaretUpFill
                        height={"20px"}
                        width={"20px"}
                        className="hover:cursor-pointer"
                        onClick={(ev) => {
                          handleOnSort2(ev, header, "asc");
                        }}
                      />
                    ) : (
                      <BsCaretUp
                        height={"20px"}
                        width={"20px"}
                        className="hover:cursor-pointer"
                        onClick={(ev) => {
                          handleOnSort2(ev, header, "asc");
                        }}
                      />
                    )}
                    {isDesc(sortMapping[header.id]) ? (
                      <BsCaretDownFill
                        height={"20px"}
                        width={"20px"}
                        className="hover:cursor-pointer"
                        onClick={(ev) => {
                          handleOnSort2(ev, header, "desc");
                        }}
                      />
                    ) : (
                      <BsCaretDown
                        height={"20px"}
                        width={"20px"}
                        className="hover:cursor-pointer"
                        onClick={(ev) => {
                          handleOnSort2(ev, header, "desc");
                        }}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex flex-row justify-center items-center gap-1 pt-1">
                <p className="m-0 p-0">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </p>
              </div>
            </CellContainerWithRef>
            { header.id !== "select" && (
              <div
              className="absolute top-0 right-0 cursor-pointer"
              ref={dragRef}
            >
              <GripVertical size={"13px"} />
            </div>
            )}
            
          </div>
        </th>
        
      </>
    );
  };
  const pageSizeOptions = [10, 25, 50];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters: filtered,
      columnVisibility: {
        State: false,
      },
      columnOrder,
      columnPinning: {
        // right: ["$/Ct", "Rap %", "Total Price"],
      },
      // rowSelection,
    },
    groupedColumnMode: "reorder",
    initialState: {
      pagination: {
        pageSize: 25,
        pageIndex: 0,
      },

      selectedField: selectedField,
    },
    pageCount: Math.ceil(totalItemCount / 25),
    manualPagination: true,
    autoResetPageIndex: false,
    manualSorting: true,
    onColumnOrderChange: setColumnOrder,
    meta: {
      handleRepricingChange: (rowIndex, columnId, value) => {
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              "$/Ct": value.a,
              "Rap %": value.b,
              "Total Price": value.c,
            };
          }
          return row;
        });

        dispatch(setUserTable(newData));
      },

      handleRepricingReset: (rowIndex, columnId, value) => {
        // console.log(value);
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              "$/Ct": value.a,
              "Rap %": value.b,
              "Total Price": value.c,
            };
          }
          return row;
        });
        dispatch(setUserTable(newData));
      },

      updateEditState: (value, rowIndex) => {
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            dispatch(
              apiSlice.util.invalidateTags([`Rapnet matching ${row["LD ID"]}`])
            );
            if (value.hasOwnProperty("rap_percent")) {
              return {
                ...row,
                "Rap %": value["rap_percent"],
                "$/Ct": value["price_per_carat"],
                "Total Price": value["updated_price"],
                "Rap Diff": value["Rap Diff"],
                "LD Rank": value["updated_ld_rank"],
                "LD Rank %": value["LD Rank %"],
                "Rap Rank": value["updated_rapnet_rank"],
                State: value["state"],
                Comment: value["notes"] ? value["notes"] : row["Comment"],
                "Reprice Date": value["repriced_datetime"],
              };
            } else if (value.hasOwnProperty("notes")) {
              return {
                ...row,

                Comment: value["notes"] ? value["notes"] : row["Comment"],
              };
            }
          }
          return row;
        });
        dispatch(setUserTable(newData));
        dispatch(apiSlice.util.invalidateTags(["priceTable"]));
        // Below "setDiamondRepriced" is a hack to bypass the current tracking of whether the row is clicked or repricing cell needs to be opened
        // Is this even required? Can the current check be removed completely? 
        // We are using a redux to track whether the row is clicked after pricing an item as the current way to track doesn't work because onClose is not triggered as expected on repricing a stone (which closes automatically upon calling dispatch invalidate table data)
        dispatch(setDiamondRepriced(true));
      },
    },
    filterFns: {
      filter,

      singleRowFilter: (row, col, value) => {
        const item = row.getValue(col);

        if (value.includes(item)) {
          return false;
        }

        return true;
      },

      ratioFilter: (row, col, value) => {
        const length = row.original["length"];
        const width = row.original["width"];

        const val = length / width;

        return isValueInRange(val, value.min, value.max);
      },

      multiValueFilter: (row, col, value) => {
        if (row.getValue(col)) {
          const item = row.getValue(col)?.split(",");
          return !item.some((element) => value.includes(element.toLowerCase()));
        }

        return false;
      },

      //value => ["l","g","na"]
      singleValueFilter: (row, col, value) => {
        let item = row.getValue(col);

        if (
          item === null ||
          item === "" ||
          item.toLowerCase() === "na" ||
          item.toLowerCase() === "n/a"
        ) {
          item = "NA"; // Set item to "NA"
          return !value.includes("NA");
        }

        if (value.includes(item.toLowerCase())) {
          return false; // Item found in value, return false
        }

        return true;
      },

      minMaxFilter: (row, col, value) => {
        const val = row.getValue(col);

        return isValueInRange(val, value.min, value.max);
      },

      measurementFilter: (row, col, value) => {
        const measurementArray = row.original["Measurements"]
          .split("x")
          .map((el) => el.trim());

        const length = measurementArray[0];
        const width = measurementArray[1];
        const height = measurementArray[2];

        const valueLength = value["Length"] ?? { min: "", max: "" };
        const valueWidth = value["Width"] ?? { min: "", max: "" };
        const valueHeight = value["Height"] ?? { min: "", max: "" };
        return filterValues(
          {
            L: length,
            W: width,
            H: height,
          },
          valueLength.min ?? "",
          valueLength.max ?? "",
          valueWidth.min ?? "",
          valueWidth.max ?? "",
          valueHeight.min ?? "",
          valueHeight.max ?? ""
        );
      },
    },
    // onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });
  
  const handleRowClick = (rowData) => {
    const rowId = rowData["LD ID"];
    setSelectedRowId(rowId);
    navigate(`/price_co-pilot/${rowId}`, { state: { selectedRowId: rowId } });
    dispatch(setSelectedItem(rowData));
    dispatch(setItemStore(rowId));
    dispatch(setSelectedDiamondRow(rowId));
  };

  useEffect(() => {
    const selectedItemsList = allSelected
      ? [{ itemIDs: 'All', certNum: 'All' }]
      : selectedItems;
    onSelectionChange(selectedItemsList);
    dispatch(setUserTable(data));
  }, [selectedItems, allSelected]);

//   filter(Boolean) is a shorthand way to remove falsy values (like null, undefined, false, 0, NaN, or empty strings) from an array. It works because:

// The Boolean function, when used as a callback for filter(), converts each item to its boolean equivalent.
// Truthy values are kept, while falsy values are removed.
// This mapping operation might produce an array that includes null values 
// (when an item is not found in data). 
// The filter(Boolean) at the end removes these null values, 
// ensuring that the resulting array only contains valid objects.

  useEffect(() => {
    // setRowSelection({});
    setAllSelected(false);
    setSelectedItems([]);
  }, [currentPage,pageSize, filtered, data]);

  
  const [paginationData, setPaginationData] = useState({
    pageIndex: table.getState().pagination.pageIndex + 1,
    pageArray: [],
  });

  useEffect(() => {
    const len = data.length;
  }, [paginationData]);

  // index of the elements where we have to add a seperation border,
  const borderHeader = [
    "Cert",
    "Color",
    "Rap $/Ct",
    "Rap Rank",
    "Aging",
    "Measurements",
  ];

  return (
    <div className="relative h-full overflow-hidden w-full flex flex-col">
      <div
        className={cn(
          " h-full relative",
          isFetching && !currentData ? "overflow-hidden" : "overflow-auto", // if the data is getting fetched then hide the scroll bar.
          data.length == 0 ? "overflow-hidden" : "overflow-auto"
        )}
      >
        <div className="relative ">
          <table
            className={cn("rounded-t-lg overflow-clip w-full px-6 ", className)}
            border="0"
            cellPadding="0"
            cellSpacing="0"
            ref={tableRef}
          >
            <thead
              className={cn(
                "bg-[#F6F9FF]  px-1 sticky z-10 top-0"
                // hideHeader ? "h-0" : ""
              )}
            >
              {table.getHeaderGroups().map((headerGroup) => (  
                <tr key={headerGroup.id} className="sticky">
                  {headerGroup.headers.map((header) => (
                    <DraggableColumnHeader
                      key={header.id}
                      header={header}
                      table={table}
                      headerGroup={headerGroup}
                    />
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {/* if the loading then do not render the table row. */}
              {(isFetching && !currentData) || filterLoading ? (
                <></>
              ) : (
                <>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={cn(
                        "bg-[#FFFFFF] !h-10 whitespace-nowrap border-b-[1px] border-[#ECECEC] !relative max-h-[55px] overflow-hidden hover:bg-[#F6F9FF] hover:cursor-pointer box-border",
                        trClassName,
                        itemStore.includes(row.original["LD ID"])
                          ? "!bg-[#fbfbc9]"
                          : "",
                        row.original["State"] == "repriced"
                          ? "bg-[#A4D9FF] bg-opacity-[0.1]"
                          : ""
                      )}
                      ref={row.original["LD ID"] === selectedDiamondRow ? selectedRowRef : null}
                      onClick={(e) => {
                        const selection = window.getSelection();

                        // amplitude events.
                        const buttonClickedEvent = {
                          event_type: `v2_pricetable_rowclick`,
                          event_properties: {
                            LD_ID: row.original["LD ID"],
                          },
                        };
                        amplitude.track(buttonClickedEvent);
                        if (selection.type !== "Range") {
                          if (
                            table.getState().selectedField.columnId == null ||
                            table.getState().selectedField.rowIndex == null ||
                            diamondRepriced
                          ) {
                            navigate(
                              `/price_co-pilot/${row.original["LD ID"]}`,
                              {
                                state: {
                                  itemData: mapping(row.original),
                                  prrId: row.original["PRR ID"],
                                },
                              }
                            );
                            dispatch(setSelectedItem(row.original));
                            dispatch(setItemStore(row.original["LD ID"]));
                            dispatch(setSelectedDiamondRow(row.original["LD ID"]));
                          }
                        }
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        switch (cell.column.id) {
                          case "Rap Rank Diff":
                            return <RapChangeCell cell={cell} diffValue={cell.getValue()} threshold={5} original={cell.row.original["Rap Rank"]} text="Rank" trackingDate={cell.row.original["trackingDate"]} addedCount={cell.row.original["Supply Count"]} soldCount={cell.row.original["Demand Count"]}/>;
                          
                          case "Rap Count Diff":
                            return <RapChangeCell cell={cell} diffValue={cell.getValue()} threshold={0} original={cell.row.original["Rap Matching count"]} text="Count" trackingDate={cell.row.original["trackingDate"]} addedCount={cell.row.original["Supply Count"]} soldCount={cell.row.original["Demand Count"]}/>;

                          case "Rank Change (30 D)":
                            return <RapChangeCell cell={cell} diffValue={cell.getValue()} threshold={5} original={cell.row.original["Rap Rank"]} text="Rank" trackingDate={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)} addedCount={cell.row.original["Added (30 D)"]} soldCount={cell.row.original["Sold (30 D)"]}/>;

                          case "Count Change (30 D)":
                            return <RapChangeCell cell={cell} diffValue={cell.getValue()} threshold={0} original={cell.row.original["Rap Matching count"]} text="Count" trackingDate={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)} addedCount={cell.row.original["Added (30 D)"]} soldCount={cell.row.original["Sold (30 D)"]}/>;

                          case "LD Reco Diff":
                            return <LdRecodiff data={data} cell={cell} />;

                          case "Aging":
                            return <Aging data={data} cell={cell} />;

                          case "Rap Matching count":
                            return <RapCount data={data} cell={cell} filters={row.original["Rank Filter Set"] || [] } totalCount={row.original["Total Market Count"]}/>;

                          case "Use Rap":
                            return <LdReco data={data} cell={cell} />;

                          case "Rap Rank":
                            return <RapRank data={data} cell={cell} filters={row.original["Rank Filter Set"] || [] } />;

                          case "Sold":
                            return <MarketDemandSupplyCell cell={cell} trackingDate={cell.row.original["trackingDate"]} text="delisted in"/>;

                          case "Added":
                            return <MarketDemandSupplyCell cell={cell} trackingDate={cell.row.original["trackingDate"]} text="added in"/>;
                          
                          case "Sold (30 D)":
                            return <MarketDemandSupplyCell cell={cell} trackingDate={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)} text="delisted in"/>;

                          case "Added (30 D)":
                            return <MarketDemandSupplyCell cell={cell} trackingDate={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)} text="added in"/>;
                            
                          case "Total Availability":
                            return <RapCount data={data} cell={cell} filters={row.original["Rapnet Filter Set"] || [] } totalCount={null}/>;

                          case "Stock Num":
                            return (
                              <td
                                key={cell.id}
                                className="!h-10 text-left px-1"
                              >
                                <CellContainer
                                  className={
                                    "text-[0.685rem] font-medium pt-1 overflow-hidden  "
                                  }
                                >

                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </CellContainer>
                              </td>
                            );

                            case "select":
                                return (
                                  <td
                                    key={cell.id}
                                    className="h-8"
                                    onClick={(e) => e.stopPropagation()} // Prevent default event for the "select" column cell
                                  >
                                    <CellContainer
                                      className={
                                        "text-[0.485rem] mx-auto font-medium  justify-center pt-1"
                                      }
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </CellContainer>
                                  </td>
                                );  

                          default:
                            return (
                              <td key={cell.id} className="h-8">
                                <CellContainer
                                  className={
                                    "text-[0.685rem] font-medium  justify-center pt-1"
                                  }
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </CellContainer>
                              </td>
                            );
                        }
                      })}
                      <td className="absolute left-14 top-0 flex gap-2">
                        {row.original["Rank Filter Set"] ? (
                          <div key='default-lock-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                            {/* {console.log("for item with cert ", row.original["Cert Num"], " filter is locked: ", row.original["Is Filter Locked"])} */}
                            <TableLockFilter
                              filters={row.original["Rank Filter Set"] }
                              isLocked={row.original["Is Filter Locked"]}
                              itemId={row.original["LD ID"]}
                              certNum={row.original["Cert Num"]}
                              shape={row.original["Shape"]}
                              size={row.original["Carat"]}
                              color={row.original["Color"]}
                              clarity={row.original["Clarity"]}
                              cut={row.original["Cut"]}
                              polish={row.original["Polish"]}
                              sym={row.original["Sym"]}
                              flr={row.original["Fluor"]}
                              stockNum={row.original["Stock No"]}
                            />
                          </div>
                        ) : (
                          <div key='default-lock-open-{cell.id}' className="h-full text-[0.56rem] font-bold px-1  bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                            <FaLockOpen height={"10px"} />
                          </div>
                        )}
                        {row.original["Rap Filter Expanded"] ? (
                          <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                            <ExpandedFilter
                              isFilterFormatTextOnly={true}
                              filters={row.original["Rapnet Filter Set"]}
                              isIcon={false}
                            />
                          </div>
                        ) : (
                          <div className="h-full text-[0.56rem] font-bold px-1  rounded-b-lg whitespace-nowrap text-[#844700]">
                            <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                            {/* <span> RN </span> */}
                          </div>
                        )}

                        {row.original["Comment"]?.length > 0 ? (
                          <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                            {<Comment data={row.original["Comment"]} />}
                          </div>
                        ) : (
                          <div className="h-full text-[0.56rem] font-bold px-1 rounded-b-lg whitespace-nowrap text-[#844700]">
                            <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                            {/* <span> NC </span> */}
                          </div>
                        )}
                        {row.original["Has Videos"] ? (
                          <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                            {<FaCamera height={"10px"} />}
                          </div>
                        ) : (
                          <div className="h-full text-[0.56rem] font-bold px-1 rounded-b-lg whitespace-nowrap text-[#844700]">
                            <span> &nbsp;&nbsp; </span>
                            {/* <span>NV</span> */}
                          </div>
                        )}


                        {[
                          // row.original["Tag"],
                          row.original["Cert Num"],
                          // row.original["Location"],
                          // row.original["Updated_At"],
                        ].map((label) => {
                          // console.log(label);
                          // write a label array generation method which generates the array on the fly

                          return (
                            <>
                              {label ? (
                                <div
                                  key='{label}-{cell.id}'
                                  className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest"
                                >
                                  {label
                                    ? ["re_pricing", "new_pricing"].includes(
                                        label
                                      )
                                      ? label == "re_pricing"
                                        ? "REPRICING"
                                        : "NEW"
                                      : label.toUpperCase()
                                    : ""}
                                </div>
                              ) : null}
                            </>
                          );
                        })}

                        {/* For "Cost $/Ct" */}

                        {row.original["Cost $/Ct"] && row.original["Rap $/Ct"] && (
                          <div key='cost-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            Cost Rap: {row.original && row.original["Cost $/Ct"] !== undefined && row.original["Rap $/Ct"] !== undefined
                                        ? Math.round((((row.original["Cost $/Ct"] - row.original["Rap $/Ct"]) / (row.original["Rap $/Ct"] || 1)) * 100.0) * 100) / 100
                                        : "-"}
                          </div>
                        )}

                        {/* For "Buyer Name" */}
                        {row.original["Buyer Name"] && (
                          <div key='buyer-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            Buyer: {row.original["Buyer Name"]}
                          </div>
                        )}
                        {/* For "Internal Spec" */}
                        {row.original["Internal Spec"] && (
                          <div key='internal_spec-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            Spec: <span className="uppercase"> {row.original["Internal Spec"]} </span>
                          </div>
                        )}

                        {/* Amipi Custom columns */}
                        {row.original["Memo Date"] && (
                          <div key='memo_date-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            Last Memo Date: <span className="uppercase"> {row.original["Memo Date"]} </span>
                          </div>
                        )}

                        {row.original["Memo Count"] && (
                          <div key='memo_count-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            Memo Count: <span className="uppercase"> {row.original["Memo Count"]} </span>
                          </div>
                        )}

                        {row.original["Memo Stock"] && (
                          <div key='memo_sock-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            <span className="uppercase"> {row.original["Memo Stock"]} </span>
                          </div>
                        )}

                        {/* For "Internal Spec" */}
                        {/*{row.original["Buy Date"] && (
                          <div key='purchase_date-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            P Date: <span className="uppercase"> {row.original["Buy Date"]} </span>
                          </div>
                        )}*/}

                        {/* For "Internal Spec" */}
                        {row.original["Custom tag"] && (
                          <div key='custom_tag-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            <span className="uppercase"> {row.original["Custom tag"]} </span>
                          </div>
                        )}

                        {/* For "Internal Spec" */}
                        {row.original["Add Spec"] && (
                          <div key='add_spec-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                            Comment: <span className="uppercase"> {row.original["Add Spec"]} </span>
                          </div>
                        )}

                        {row.original["Logs"]?.length > 0 ? (
                          <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                            {<Logs data={row.original["Logs"]} />}
                          </div>
                        ) : (
                          <div className="h-full text-[0.56rem] font-bold px-1 rounded-b-lg whitespace-nowrap text-[#844700]">
                            <span> &nbsp;&nbsp; </span>
                            {/* <span> NL </span> */}
                          </div>
                        )}

                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {/* right */}
          
        </div>

        {/* adding loader state. */}
        {(isFetching && !currentData) || filterLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {data.length == 0 ? (
          <div className="w-full h-full flex justify-center items-center flex-col">
            No Diamonds to Display
            {/* <Link to={"/#home"}> Click to go Home</Link> */}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className=" bottom-0 min-h-[46px] max-h-[46px]  bg-white border border-solid border-[#EAEAEA] w-full overflow-hidden z-[99]">
        <TablePagination
          component="div"
          count={totalItemCount}
          page={currentPage}
          onPageChange={setCurrentPage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={setPageSize}
          rowsPerPageOptions={pageSizeOptions}
        />
        <div className="h-2" />
      </div>
    </div>
  );
};

export default PriceTable;