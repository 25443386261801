import React from 'react'

const SummaryCard = ({ summaryKey, value, handleShowDrillDown, showDrops, headerSubText, subKey, subValue, turn }) => {

    const ICON_HASH_FOR_SUMMERY = { 'Total Price': ['bi-safe2', 'sales-card'], 'Average Price': ['bi-currency-dollar', 'revenue-card'], 'Count': ['bi-list-ol', 'customers-card'] }


    return (
        <div className="col-xxl-4 col-md-12 col-lg-4 col-sm-12">
            <div className={"card info-card " + ICON_HASH_FOR_SUMMERY[summaryKey][1]}>

                {
                    showDrops ? <div className="filter">
                        <a className="icon" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <li className="dropdown-header text-start"><h6>Options</h6></li>
                            <li><a className="dropdown-item" onClick={handleShowDrillDown}>View Stones</a></li>
                        </ul>
                    </div> : null
                }

                <div className="card-body" style={{ overflow: "hidden" }}>
                    <h5 className="card-title">{summaryKey} <span>| {headerSubText}</span></h5>

                    <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={handleShowDrillDown}>
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className={"bi " + ICON_HASH_FOR_SUMMERY[summaryKey][0]}></i>
                        </div>
                        <div className="ps-3">
                            <h6>{value}</h6>
                            {subValue ? (<span className='summaryCardSubSpan'>{subKey}: <strong>{subValue}</strong></span>) : ""}
                            {turn ? (<span className='summaryCardSubSpan'>, Turn: <strong>{turn}</strong></span>) : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryCard