import React from "react";

import { NavLink, useLocation } from "react-router-dom";
import { cn } from "../utils/utils";
import { motion } from "framer-motion";
import {
  CandlestickChart,
  ClipboardEdit,
  FileClock,
  LineChart,
} from "lucide-react";
import { Tooltip } from "@mui/material";
import {PiVaultBold} from 'react-icons/pi'
import {IoCartOutline,  IoPricetagsOutline} from "react-icons/io5"
import { HiOutlineShoppingCart } from "react-icons/hi";
import { TbReportSearch } from "react-icons/tb";
const SidebarContent = ({ isVisible }) => {
  const routes = {
    "/price_co-pilot": "Pricing Co-Pilot",
    "/inventory_report": "BI-Inventory",
    "/sale_report": "BI-Sales",
    "/saved_search": "Saved Searches",
    // "/aging_report": "Aging Report",
  };

  const icons = {
    "/inventory_report": (
      <PiVaultBold className=" h-4 w-4 text-[#4154f1]"  />
    ),
    "/sale_report": (
      <HiOutlineShoppingCart className=" h-5 w-5 text-[#4154f1]" />
    ),
    // "/aging_report": (
    //   <FileClock height={"16px"} width={"16px"} color="#4154f1" />
    // ),
    "/price_co-pilot": (
      <IoPricetagsOutline className=" h-5 w-5 text-[#4154f1]" />
    ),
    "/saved_search": (
      <TbReportSearch className=" h-5 w-5 text-[#4154f1]" />
    ),
  };

  const location = useLocation();

  const variants = {
    visible: {
      opacity: 1,
      width: "100%",
      display: "flex",
      transition: { duration: 0.3, delay: 0.2 },
    },
    hidden: {
      opacity: 0,
      width: "0%",
      display: "none",
      transition: { duration: 0.2 },
    },
  };

  return (
    <>
      {Object.entries(routes).map(([k, v]) => (
        <NavLink to={k} style={{ textDecoration: "none" }} key={k}>
          {/* {shouldRenderContent ? (
            
          ) : null} */}
          <motion.div
            className={cn(
              "py-2 px-4 cursor-pointer hover:bg-[#f6f9ff] rounded-lg mt-1 flex items-center gap-2 text-[#4154f1] font-semibold text-sm whitespace-nowrap",
              location.pathname.toString().includes(k) ? "bg-[#e0e7f5]" : "",
              !isVisible ? "px-2 w-full " : ""
            )}
            variants={variants}
            initial={isVisible ? "visible" : "hidden"}
            animate={isVisible ? "visible" : "hidden"}
            exit="hidden"
            style={{ whiteSpace: "nowrap" }}
          >
            <div className="h-4 w-4 flex justify-center items-center ">
              {icons[k]}
            </div>
            {v}
          </motion.div>
         <Tooltip title={routes[k]}> 
          <motion.div
            className={cn(
              "py-2 px-4 cursor-pointer hover:bg-[#f6f9ff] rounded-lg mt-1 flex items-center justify-center gap-2 text-[#4154f1] font-semibold text-sm whitespace-nowrap",
              location.pathname.toString().includes(k) ? "bg-[#e0e7f5]" : "",
              !isVisible ? "px-2 w-full " : ""
            )}
            variants={variants}
            initial={!isVisible ? "visible" : "hidden"}
            animate={!isVisible ? "visible" : "hidden"}
            exit="hidden"
            style={{ whiteSpace: "nowrap" }}
          >
            <div className="h-4 w-4 flex justify-center items-center ">
              {icons[k]}
            </div>
          </motion.div>
          </Tooltip>
        </NavLink>
      ))}
    </>
  );
};

export default SidebarContent;
