import React from 'react';
import PropTypes from 'prop-types';

const SavedSearches = ({ searches, onSearchClick }) => {
  return (
    <div style={{ paddingTop: '20px', paddingBottom: '20px'  }}>
      {/* <div className="pagetitle text-center">
        <h1>SAVED SEARCHES</h1>
      </div> */}
      {searches.length > 0 ? (
        <ol style={{ listStyleType: 'none', padding: 0 }}>
          {searches.map((search) => (
            <li
              key={search.id}
              style={{
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '5px',
                marginBottom: '10px',
                cursor: 'pointer',
                backgroundColor: '#f9f9f9',
              }}
              onClick={() => onSearchClick(search)}
            >
              <strong>{search.name}</strong>
              <div>
                <ul>
                        {search.display_criteria.map((criterion, index) => (
                          <li key={index}>{criterion.replace('_', ' ')}</li>
                        ))}
                </ul>
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <div className="pagetitle text-center">
          <h3>No saved searches found.</h3>
        </div>
      )}
    </div>
  );
};

SavedSearches.propTypes = {
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      criteria: PropTypes.object.isRequired,
    })
  ).isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default SavedSearches;
