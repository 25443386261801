import React, {useState} from "react";
import { ColorRing } from "react-loader-spinner";
import "./StaticReport.css";
import { ArrowDown, ArrowUp } from "lucide-react/dist/cjs/lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsPreFiltered, setPreDefinedFilter } from "../../redux/slices/pricingSlice";

const StaticReport = ({ DataisLoaded, items, setState, id, filterHash , rowFilters}) => {
  

  const navigate = useNavigate()
  const dispatch = useDispatch();

  // Handle search click
  const handleSearchClick = (search) => {
    console.log('inventory clicked:', search);
    dispatch(setIsPreFiltered(true));
    dispatch(setPreDefinedFilter(search));
    navigate(`/price_co-pilot`);
    // Implement navigation or actions with the selected search
    // We need to update the 
  };
  const [sortConfig, setSortConfig] = useState({
    table: null,
    key: null,
    direction: null, // null = original, 'asc' = ascending, 'desc' = descending
    clicks: 0
  });
  
  const getBgColor = (min, max, value) => {
    let rgb_string = "";
    if (value === max) {
      rgb_string = "rgba(100,255,90,0.7)";
    } else if (value === min) {
      rgb_string = "rgba(255,100,90,0.7)";
    } else {
      const range = max - min;
      const percentage = parseInt(((value - min) / range) * 100);
      let red, green;
      if (percentage < 50) {
        // get color between red to yellow
        red = 155 + (100 - percentage);
        green = 155 * (percentage / 50) + 100;
      } else {
        // get color between yellow to green
        red = (155 * (100 - percentage)) / 50 + 100;
        green = 155 + percentage;
      }
      rgb_string = `rgba(${red},${green},90,0.7)`;
    }
    return rgb_string;
  };

  const parseCurrencyValue = (currencyString) => {
    // Remove "$" and "," and convert to float
    return parseFloat(currencyString.replace(/[$,]/g, ''));
  };

  const handleSort = (tableKey, columnKey) => {
    let direction = null;
    let clicks = 1;

    if (sortConfig.table === tableKey && sortConfig.key === columnKey) {
      clicks = (sortConfig.clicks + 1) % 3;
      if (clicks === 1) direction = 'asc';
      else if (clicks === 2) direction = 'desc';
    } else {
      direction = 'asc';
    }

    setSortConfig({
      table: tableKey,
      key: columnKey,
      direction,
      clicks
    });
  };

  const getSortedData = (tableKey, data) => {
    if (sortConfig.table !== tableKey || !sortConfig.direction) {
      return data;
    }

    return [...data].sort((a, b) => {
      let aValue, bValue;
      
      switch(sortConfig.key) {
        case 'name':
          aValue = a.name.toLowerCase();
          bValue = b.name.toLowerCase();
          break;
        case 'count':
          aValue = a.count;
          bValue = b.count;
          break;
        case 'value':
          // Parse currency values correctly
          aValue = parseCurrencyValue(a.total_amount);
          bValue = parseCurrencyValue(b.total_amount);
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'asc') {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    });
  };

  const getSortIcon = (tableKey, columnKey) => {
    if (sortConfig.table !== tableKey || sortConfig.key !== columnKey) {
      return;
    }
    if (sortConfig.direction === 'asc') {
      return <ArrowUp className="inline h-4 w-4 text-blue-600" />;
    }
    if (sortConfig.direction === 'desc') {
      return <ArrowDown className="inline h-4 w-4 text-blue-600" />;
    }
    return;
  };

  const handleRowClick = (key, element) => {
    const TABLE_FILTER_MAP = {
      lab: "diamond#cert",
      shape: "diamond#shape",
      color: "diamond#color",
      clarity: "diamond#clarity",
      carats: "gem#carats",
      milky: "diamond#milky",
      shade: "diamond#shade",
      make: "diamond#make",
      fluor: "diamond#fluor",
      bic: "diamond#black_in_center",
      bis: "diamond#black_in_side",
      wis: "diamond#white_in_side",
      wic: "diamond#white_in_center"
    };
    // Get the corresponding filter property for the current table
    const filterProperty = TABLE_FILTER_MAP[key];

    if (filterProperty) {
      // Create a filter object
      let currentRowfilter = {};
      if(filterProperty === 'gem#carats'){
        let carat_range = element.key.split("-");
        currentRowfilter = {
          [filterProperty]: [{ to: carat_range[1], from: carat_range[0] }]
        };
      }else{
        currentRowfilter = {
          [filterProperty]: Array.isArray(element.key) ? element.key : [element.key]
        };
      }
      if(id == "sales"){
        setState((prevState) => ({
          ...prevState,
          rowFilter: currentRowfilter, // Set new rowFilter
          showDrillDown: true, // Trigger Aging Report modal
        }));
      }else if(id == "inventory"){
        setState((prevState) => ({
          ...prevState,
          rowFilter: currentRowfilter, // Set new rowFilter
        }));
        // Navigate to Pricing co-Pilot, query updated filters and show the updated filters

        const transformedFilters = transformFilters(updateCombinedFilters(currentRowfilter));
        handleSearchClick(transformedFilters)

      }
    }
  };

  let defaultFilter = {};

  if (filterHash ) {
    defaultFilter = filterHash.item_filter;
  } else defaultFilter = { discrete: { state: ["for_sale"] } };

  const updateCombinedFilters = (rowFilter) => {
    const filcombinedFilters = {
      ...defaultFilter,
      discrete: {
        ...defaultFilter.discrete,
        ...rowFilter
      },
    };
    return filcombinedFilters;
  }

  const getCutPolishSymmetryFromMake = (make) => {
    const makeMap = {
      "0000xxx": { cut: ["0000ex"], polish: ["0000ex"], symmetry: ["0000ex"] },
      "0001x_vgvg_plus": { cut: ["0000ex"], polish: ["0000ex", "0001vg"], symmetry: ["0000ex", "0001vg"] },
      "0002vg_xx": { cut: ["0000ex", "0001vg"], polish: ["0000ex"], symmetry: ["0000ex"] },
      "0003vg_vgvg_plus": { cut: ["0000ex", "0001vg"], polish: ["0000ex", "0001vg"], symmetry: ["0000ex", "0001vg"] },
      "0004vg_gdgd_plus": { cut: ["0000ex", "0001vg"], polish: ["0000ex", "0001vg", "0002good"], symmetry: ["0000ex", "0001vg", "0002good"] },
      "0005gd_xx": { cut: ["0000ex", "0001vg", "0002good"], polish: ["0000ex"], symmetry: ["0000ex"] },
      "0006gd_vgvg_plus": { cut: ["0000ex", "0001vg", "0002good"], polish: ["0000ex", "0001vg"], symmetry: ["0000ex", "0001vg"] },
      "0007gd_gdgd_plus": { cut: ["0000ex", "0001vg", "0002good"], polish: ["0000ex", "0001vg", "0002good"], symmetry: ["0000ex", "0001vg", "0002good"] }
    };
  
    const result = makeMap[make] || { cut: null, polish: null, symmetry: null };
    return [result.cut, result.polish, result.symmetry];
  };
  

  const transformFilters = (filters) => {
    const transformed = {
      item_filter: {
        discrete: {},
        from: {},
        text: {},
        to: {},
      },
    };
  
    // Process discrete filters
    if (filters.discrete) {
      for (const key in filters.discrete) {
        if (filters.discrete.hasOwnProperty(key)) {
          // Exclude gem#carats for now
          if (key !== "gem#carats" && key !== "diamond#make") {
            transformed.item_filter.discrete[key] = filters.discrete[key];
          } else if (key === "gem#carats") {
            // Combine gem#carats ranges into from and to
            const carats = filters.discrete["gem#carats"];
            let min = null;
            let max = null;
            carats.forEach((carat) => {
              if (carat.from !== undefined) {
                min = min !== null ? Math.min(min, parseFloat(carat.from)) : parseFloat(carat.from);
              }
              if (carat.to !== undefined) {
                max = max !== null ? Math.max(max, parseFloat(carat.to)) : parseFloat(carat.to);
              }
            });
            if (min !== null) transformed.item_filter.from["gem#carats"] = min.toString();
            if (max !== null) transformed.item_filter.to["gem#carats"] = max.toString();
          }
        }
      }
    }
  
    // Process rowFilters for carats (assuming it contains gem#carats)
    if (filters.discrete && filters.discrete["diamond#make"]) {
      console.log(filters.discrete["diamond#make"])
      filters.discrete["diamond#make"].forEach(make => {
        const [cut, polish, symmetry] = getCutPolishSymmetryFromMake(make);
        console.log(cut, polish, symmetry);
        if (cut) {
          if (!transformed.item_filter.discrete["diamond#cut"]) {
            transformed.item_filter.discrete["diamond#cut"] = [];
          }
          transformed.item_filter.discrete["diamond#cut"].push(...cut);
        }
        if (polish) {
          if (!transformed.item_filter.discrete["diamond#polish"]) {
            transformed.item_filter.discrete["diamond#polish"] = [];
          }
          transformed.item_filter.discrete["diamond#polish"].push(...polish);
        }
        if (symmetry) {
          if (!transformed.item_filter.discrete["diamond#symmetry"]) {
            transformed.item_filter.discrete["diamond#symmetry"] = [];
          }
          transformed.item_filter.discrete["diamond#symmetry"].push(...symmetry);
        }
      });
    }

    for (const key in transformed.item_filter.discrete) {
      if (Array.isArray(transformed.item_filter.discrete[key])) {
        transformed.item_filter.discrete[key] = [...new Set(transformed.item_filter.discrete[key])];
      }
    }
  
    return transformed;
  };


  

  return (
    <>
      {!DataisLoaded ? (
        <div className="row">
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
          <div className="align-items-center justify-content-center">
            <h3 className=" text-center"> Please wait... </h3>
          </div>
        </div>
      ) : (
        <div className="row">
          {Object.entries(items).map(([key, value]) => {
            // Getting min max amounts of each tables
            const max = Math.max(
              ...value.map((e) => parseCurrencyValue(e.total_amount))
            );
            const min = Math.min(
              ...value.map((e) => parseCurrencyValue(e.total_amount))
            );

            const sortedData = getSortedData(key, value);
            return (
              <div
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 "
                key={key}
              >
                <h5
                  className="card-title"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {key.toUpperCase()}
                </h5>
                <div
                  className="card"
                  style={{
                    minHeight: "480px",
                    maxHeight: "480px",
                    overflow: "auto",
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      paddingTop: "0px",
                    }}
                  >
                    <table className="table table-sm table-hover">
                      <thead
                        className="sticky-top bg-white"
                        style={{
                          top: -1,
                          zIndex: 100,
                          borderTop: "hidden",
                        }}
                      >
                        <tr>
                          <th 
                            className="pd-15 cursor-pointer  min-w-[80px]" 
                            onClick={() => handleSort(key, 'name')}
                          >
                            Name {getSortIcon(key, 'name')}
                          </th>
                          <th 
                            className="pd-15 cursor-pointer  min-w-[80px]" 
                            onClick={() => handleSort(key, 'count')}
                          >
                            Count {getSortIcon(key, 'count')}
                          </th>
                          <th 
                            className="pd-15 cursor-pointer  min-w-[80px]" 
                            onClick={() => handleSort(key, 'value')}
                          >
                            Value {getSortIcon(key, 'value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedData.map((element, idx) => (
                          <tr key={idx} onClick={() => handleRowClick(key, element)}>
                            <td scope="row" style={{ textAlign: "left" }}>
                              {element.name}
                            </td>
                            <td
                              scope="row"
                              style={{
                                textAlign: "right",
                                paddingRight: "15px",
                              }}
                            >
                              {element.count}
                            </td>
                            <td
                              scope="row"
                              style={{
                                backgroundColor: `${getBgColor(
                                  min,
                                  max,
                                  parseCurrencyValue(element.total_amount)
                                )}`,
                                opacity: 1,
                                textAlign: "right",
                                paddingRight: "15px",
                              }}
                            >
                              {element.total_amount.slice(0, -3)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default StaticReport;
